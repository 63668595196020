import * as THREE from "three";

function extractAnimations(gltf) {
  const animations = gltf.animations[0];
  return {
    MusclesMovement: THREE.AnimationUtils.subclip(animations, "R", 30, 92),
    jointmovement: THREE.AnimationUtils.subclip(animations, "q", 0, 125),
    involuntary: THREE.AnimationUtils.subclip(animations, "R", 0, 29),
    freelymovable: THREE.AnimationUtils.subclip(animations, "R", 160, 230),
    Slightlymovable: THREE.AnimationUtils.subclip(animations, "R", 123, 160),
  };
}

export default extractAnimations;