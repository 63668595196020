import React from "react";

const model = {
  light: {
    amp: 0.3,
    front: 0.75,
    back: 0.75,
  },
  label: "The nervous system",
  id: "nervous",
  hidden: ["Skeletal_Cranium_Low", "skull001"],
  description: "",
  camtarget: { x: 0, y: 1.38, z: -0.05 },
  camera: { x: 0.1, y: 1.4, z: 1.9 },
  targets: [
    {
      hidden: ["Outer_Body", "Skeletal_Cranium_Low", "skull001"],
      shift: [{ x: 0, y: 1.8, z: 0 }],
      direction: { x: -0.02, y: 1.9, z: -0.04 },
      label: "The brain",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.7, z: -0.05 },
      camera: { x: -0.446, y:1.946, z: 0.707 },
      targets: [
        {
          shift: [{ x: 0, y: 1.8, z: 0 }],
          direction: { x: -0.02, y: 1.87, z: 0.06 },
          label: "The brain",
          labelYShift: -1,
          camtarget: { x: 0, y: 1.66, z: -0.05 },
          camera: { x: -0.4, y: 2.2, z: 0.6 },
          openlabel: true,
          small: true,
        },
        {
          shift: [
            { x: -0.04, y: 1.81, z: -0.05 },
            { x: 0.03, y: 1.81, z: -0.05 },
          ],
          direction: { x: 0, y: 1.9, z: -0.05 },
          labelYShift: -1,
          label: "Cerebrum",
          id: "cerebrum",
          description: (
            <div>
              <h1>A) Cerebrum</h1>
              <ul>
                <li>
                  It is divided into two halves (right and left cerebral hemisph
                  by a fissure and attached to each other through nerve fibers.
                </li>
                <li>
                  The outer surface of the hemishperes have grey colour and
                  called "cerabral cortex"
                </li>
                <li>
                  The hemisphere is characterized by many convolutions and folds
                  on their surface.
                </li>
              </ul>
              <h2>The function of the cerebrum:</h2>
              <ol>
                <li>
                  Controlling the voluntary movements (running - walking )
                </li>
                <li>
                  Receiving nerve impulses from sense organs and send responses
                  to these impulses
                </li>
                <li>It contains the centers of thinking and memory</li>
              </ol>
            </div>
          ),
          camtarget: { x: 0, y: 1.82, z: -0.05 },
          camera: { x: -0.28, y: 2, z: 0.2 },
          small: true,
        },
        {
          shift: [{ x: 0, y: 1.8, z: -0.09 }],
          direction: { x: 0, y: 1.86, z: -0.11 },
          label: "Fissure",
          labelYShift: -1,
          camtarget: { x: 0, y: 1.82, z: -0.05 },
          camera: { x: -0.09, y: 2.1, z: -0.33 },
          small: true,
        },
        {
          shift: [{ x: 0.01, y: 1.8, z: 0.0 }],
          direction: { x: 0.1, y: 1.85, z: -0.02 },
          label: "Left cerebral hemisphere",
          labelYShift: -1,
          camtarget: { x: 0, y: 1.66, z: -0.05 },
          camera: { x: 0.745, y: 2.056, z:-0.305},
          small: true,
        },
        {
          shift: [{ x: -0.01, y: 1.8, z: 0.0 }],
          direction: { x: -0.06, y: 1.86, z: -0.03 },
          label: "Right cerebral hemisphere",
          labelYShift: -1,
          camtarget: { x: 0, y: 1.66, z: -0.05 },
          camera: { x: -0.4, y: 2.1, z: 0.6 },
          small: true,
        },
        {
          shift: [
            { x: 0.03, y: 1.72, z: -0.09 },
            { x: -0.03, y: 1.72, z: -0.09 },
          ],
          direction: { x: 0.0, y: 1.66, z: -0.2 },
          label: "cerebellum",
          labelYShift: 1,

          id: "cerebellum",
          description: (
            <div>
              <h1>B) Cerebellum</h1>
              <p>
                It locates below two hemispheres at the back area of the brain.
              </p>
              <h2>The function:</h2>
              <p>It keeps the balance of the body during the movement</p>
            </div>
          ),
          camtarget: { x: 0, y: 1.65, z: -0.05 },
          camera: { x: -0.05, y: 1.8, z: -0.5 },
          small: true,
        },
        {
          label: "The Spinal Cord",
          id: "spinal",
          shift: [
            { x: 0, y: 1.68, z: -0.08 },
            { x: 0, y:1.500, z:-0.120 },
          ],
          labelYShift: -1,
          description: (
            <div>
              <h1>2) Spinal cord</h1>
              <h2>The location:</h2>
              <p>
                It extends in a channel inside the vertebrae in the backbone
              </p>
              <h2>Its structure:</h2>
              <ul>
                <li>
                  The internal gray matter of the spinal cord takes the shape of
                  letter "H"
                </li>
                <li> The gray matter is surrounded by white matter</li>
              </ul>
              <h2>The function of the spinal cord:</h2>
              <ol>
                <li>
                  It transfers nerve messages from the body parts to the brain
                  and vice verse.
                </li>
                <li>
                  It is responsible for the reflexes (a group of reflex actions)
                  such as removing the hand quickly during touching hot surface
                </li>
              </ol>
            </div>
          ),
          camera: { x: -0.1, y: 1.77, z: -0.6 },
          camtarget: { x: 0, y: 1.55, z: -0.05 },
          direction: { x: 0.080, y:1.680, z: -0.150 },
          small: true,
        },
        {
          shift: [{ x: -0.0, y: 1.68, z: -0.08 }],
          direction: { x: -0.11, y: 1.64, z: -0.1 },
          label: "Medulla oblongata",
          labelYShift: 1,
          id: "oblongata",
          description: (
            <div>
              <h1> The medula oblongata</h1>
              <ul>
                <li>
                  It locates at the bottom of the cerebellum (or above the
                  spinal cord).
                </li>
                <li>It connects the brain with the spinal cord.</li>
              </ul>
              <h2>The function of the medulla oblongata:</h2>
              <p>It regulates the involuntary processes such as:</p>
              <ul>
                <li>Heart Breats</li>
                <li>Movement of digestive system.</li>
                <li>Movement of respiratory system during breathing.</li>
              </ul>
            </div>
          ),
          camtarget: { x: 0, y: 1.69, z: -0.05 },
          camera: { x: -0.410, y:1.625, z: -0.411 },
          childs: [],
          small: true,
        },
      ],
    },
    {
      label: "The peripheral nervous system",
      id: "peripheral",
      shift: [{ x: -0.33, y: 1.439, z: -0.178 }],
      direction: { x: -0.33, y: 1.5, z: -0.178 },
      labelYShift: -1,
      camera: { x: -0.693, y: 1.137, z: 0.89 },

      camtarget: { x: 0, y: 1.42, z: -0.05 },

      description: (
        <div>
          <h1>The peripheral nervous system</h1>
          <p>
            It is a group of nerves emerge from the brain and the spinal cord
          </p>
          <p>
            The peripheral nervous system is divided into two types which are
          </p>
          <ol>
            <li>
              <b>cranial nerves</b>: <br /> They are 12 pairs of nerves emerge
              from the brain.
            </li>
            <li>
              <b>Spinal nerves:</b> <br />
              the are 31 pairs of nerves emerge from spinal cord.
            </li>
          </ol>
          <h2>Its function:</h2>
          <p>
            It delivers the sensors information and the kinetic responses
            between the centeral nervous system and all parts of the body.
          </p>
        </div>
      ),
      hidden: [
        "Outer_Body",
        "skull001",
        "Skeletal_Mandible_Low",
        "Skeletal_Cranium_Low",
        "Nervous_Cerebellum_Low",
        "Spinal_Cord",
      ],
      targets: [
        {
          label: "The peripheral nervous system",
          id: "peripheral",
          shift: [{ x: -0.33, y: 1.439, z: -0.178 }],
          direction: { x: -0.35, y: 1.49, z: -0.19 },
          labelYShift: -1,
          camera: { x: -0.992, y: 1.006, z: 1.296 },

          camtarget: { x: 0, y: 1.42, z: -0.05 },
          description: (
            <div>
              <h1>The peripheral nervous system</h1>
              <p>
                It is a group of nerves emerge from the brain and the spinal
                cord
              </p>
              <p>
                The peripheral nervous system is divided into two types which
                are
              </p>
              <ol>
                <li>
                  <b>cranial nerves</b>: <br /> They are 12 pairs of nerves
                  emerge from the brain.
                </li>
                <li>
                  <b>Spinal nerves:</b> <br />
                  the are 31 pairs of nerves emerge from spinal cord.
                </li>
              </ol>
              <h2>Its function:</h2>
              <p>
                It delivers the sensors information and the kinetic responses
                between the centeral nervous system and all parts of the body.
              </p>
            </div>
          ),
          small: true,
          openlabel: true,
        },
        {
          shift: [
            { x: -0.157, y: 1.362, z: -0.05 },
            { x: -0.171, y: 1.279, z: -0.05 },
            { x: -0.171, y: 1.212, z: -0.08 },
          ],
          direction: { x: -0.25, y: 1.2, z: 0 },
          label: "Spinal nerves",
          // small: true,
          camera: { x: -0.58, y: 1.446, z: 0.8 },
          camtarget: { x: 0, y: 1.38, z: -0.05 },
          labelYShift: 1,
        },
        {
          shift: [
            { x: -0.025, y: 1.7, z: -0.08 },
            { x: 0.025, y: 1.7, z: -0.08 },
            { x: -0.044, y: 1.7, z: -0.08 },
            { x: 0.044, y: 1.7, z: -0.08 },
          ],
          direction: { x: -0.04, y: 1.68, z: -0.2 },
          label: "Cranial Berves",
          labelYShift: 1,
          // small: true,
          camera: { x: -0.52, y: 1.755, z: -0.86 },
          camtarget: { x: 0, y: 1.5, z: -0.05 },
        },
      ],
    },
  ],
  //   {
  //     targets: [
  //       {
  //         shift: [
  //           { x: -0.025, y: 1.7, z: -0.08 },
  //           { x: 0.025, y: 1.7, z: -0.08 },
  //           { x: -0.044, y: 1.7, z: -0.08 },
  //           { x: 0.044, y: 1.7, z: -0.08 },
  //         ],
  //         direction: { x: 0.04, y: 1.64, z: -0.2 },
  //         label: "Cranial Berves",
  //         color: "g",
  //         labelYShift: 1,
  //       },
  //     ],
  //     camera: { x: 0.15, y: 1.6, z: 0.8 },
  //     childs: [],
  //   },
  // ],
};

export default model;
