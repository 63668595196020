import React from "react";
import Icon from "../IconComponents/ToggleIcon";
import { handleSpriteClick } from "../components/Model";
import { scene } from "../components/setup";
import "./hotController.css";
import extract from "../helpers/extract";

export default function HotController({
  ActiveID,
  setActiveID,
  NavigationItems,
}) {
  // Here's the plan:
  /* 
    *** PLANE NO_1 ***
    1- Know the depth of the layer
      1.1) Get layers name from ***NavigationItems*** state
    2- Access that layer from the scene
    3- append the label to it
  */

  function extractTarget() {
    return extract(
      extract(scene, "indexLables"),
      NavigationItems[NavigationItems.length - 1].label
    );
  }

  function findIndex() {
    if (ActiveID === "") {
      return 1;
    } else {
      const parent = NavigationItems[
        NavigationItems.length - 1
      ].targets.findIndex((e) => e.label === ActiveID);
      return parent;
    }
  }

  function goToTarget(index){
    const target = NavigationItems[NavigationItems.length - 1].targets[index];
    handleSpriteClick(target, extractTarget());
  }

  function handlePrevClick() {
    let index = findIndex();
    if (index <= 0) {
      index = NavigationItems[NavigationItems.length - 1].targets.length - 1;
    } else {
      index = index - 1;
    }
   goToTarget(index)
  }

  function handleNextClick() {
    let index = findIndex();

    if (
      index ===
        NavigationItems[NavigationItems.length - 1].targets.length - 1 ||
      index == -1
    ) {
      index = 0;
    } else {
      index = index + 1;
    }
    goToTarget(index)
  }

  return (
    <div className="hotController">
      <div className="hotKey" onClick={handlePrevClick}>
        <Icon width={16} height={16} />
      </div>
      <div className="hotKey next" onClick={handleNextClick}>
        <Icon width={16} height={16} />
      </div>
    </div>
  );
}
