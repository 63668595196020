const sounds = {
  digestive: require("../audio/Structure.ogg"),
  canal: require("../audio/canal.ogg"),
  Mouth: require("../audio/Mouth.ogg"),
  Pharynx: require("../audio/Pharynx.ogg"),
  Esophagus: require("../audio/Esophagus.ogg"),
  Stomach: require("../audio/Stomach.ogg"),
  "Small Intestine": require("../audio/SmallIntestine.ogg"),
  Duodenum: require("../audio/deudenum.ogg"),
  Ileum: require("../audio/Ileum.ogg"),
  "Large Intestine": require("../audio/LargeIntestine.ogg"),
  Supplementaries: require("../audio/Supplementaries.ogg"),
  "Salivary Glands": require("../audio/Glands.ogg"),
  Liver: require("../audio/Liver.ogg"),
  Pancreas: require("../audio/Pancreas.ogg"),
};

export default sounds;
