const sounds = {
  loca: require("../audio/loca.ogg"),
  skeletal: require("../audio/skeletal.ogg"),
  Axial: require("../audio/Axial.ogg"),
  skull: require("../audio/Skull.ogg"),
  backbone: require("../audio/backbone.ogg"),
  ribcage: require("../audio/ribcage.ogg"),
  Appendicular: require("../audio/Appendicular.ogg"),
  upperlimb: require("../audio/upper.ogg"),
  lowerlimb: require("../audio/lower.ogg"),
  muscularsys: require("../audio/muscular.ogg"),
  voluntary: require("../audio/voluntary.ogg"),
  involuntary: require("../audio/involuntary.ogg"),
  jointmovement: require("../audio/Joints.ogg"),
  immovable: require("../audio/immovable.ogg"),
  Slightlymovable: require("../audio/Slightly.ogg"),
  freelymovable: require("../audio/freely.ogg"),
  MusclesMovement: require("../audio/movemetns.ogg"),
};

export default sounds;
