import React from "react";

const model = {
  light: {
    amp: 0.4,
    front: 0.8,
    back: 0.8,
  },
  label: "The respiratory system",
  id: "respi",
  description: "",
  hidden: ["outerBody"],
  camtarget: { x: 0, y: 1.52, z: -0.04 },
  camera: { x: 0.2, y: 1.6, z: 1 },
  targets: [
    {
      shift: [{ x: 0.011, y: 1.68, z: 0.03 }],
      direction: { x: 0.06, y: 1.72, z: 0.05 },
      label: "Nose",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.6, z: -0.1 },
      camera: { x: 0.1, y: 1.77, z: 0.6 },
      description: (
        <div>
          <h1>1) Nose</h1>
          <h2>Its function:</h2>

          <ol>
            <li>
              It has mucous layer and hair to filter and obstruct dust and
              microbes before the entering of air to the lungs.
            </li>
            <li>
              It has blood capillaries to warm the air as it passes through.
            </li>
          </ol>
          <h2>Note:</h2>
          <p>
            From the previous explanation we can conclude that: Breathing
            through nose is preferable to that through the mouth as inhaling
            through the mouth causes Human infection with many diseases.
          </p>
        </div>
      ),
    },
    {
      shift: [{ x: 0.0, y: 1.66, z: -0.04 }],
      direction: { x: -0.07, y: 1.7, z: -0.05 },
      label: "Epiglottis",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.72, z: -0.1 },
      camera: { x: 0.4, y: 1.7, z: -0.33 },
      description: (
        <div>
          <h1>2) Epiglottis</h1>
          <p>
            Which closes off the trachea during swallowing to prevent food from
            entering the trachea.
          </p>
        </div>
      ),
    },
    // put direction here
    {
      shift: [{ x: 0.025, y: 1.64, z: -0.02 }],
      direction: { x: 0.1, y: 1.66, z: 0.0 },
      label: "Pharynx",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.6, z: -0.1 },
      camera: { x: 0.3, y: 1.7, z: 0.3 },
      description: (
        <div>
          <h1>B) Pharynx</h1>
          <p>
            It is a common cavity leads to the esophagus{" "}
            <i>(in digestive system)</i>, and trachea{" "}
            <i>(In respiratory system)</i>.
          </p>
        </div>
      ),
    },
    {
      shift: [{ x: 0.0, y: 1.54, z: -0.07 }],
      direction: { x: -0.09, y: 1.58, z: -0.01 },
      label: "Trachea",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.7, z: -0.1 },
      camera: { x: -0.2, y: 1.5, z: 0.4 },
      description: (
        <div>
          <h1>(4) Trachea:</h1>
          <h2>Its functions</h2>
          <ol>
            <li>
              It has incomplete cartilaginous rings to keep the trachea open all
              the time.
            </li>
            <li>
              It is lined with cilia to eject up <i>(push out)</i> the strange
              objects that may enter the trachea.
            </li>
          </ol>
          <h2>
            Larynx <i>(voice box)</i>:
          </h2>
          <p>Which is responsible for producing the human voice.</p>
        </div>
      ),
    },
    {
      shift: [{ x: 0.009, y: 1.45, z: -0.105 }],
      direction: { x: 0.12, y: 1.52, z: -0.05 },
      label: "Bronchi",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.5, z: -0.1 },
      camera: { x: 0.1, y: 1.51, z: 0.5 },
      description: (
        <div>
          <h1>(5) Bronchi</h1>
          <p>Two bronchi that enter the lungs.</p>
          <h2>Note:</h2>
          <p>The two bronchi allow the air to pass in and out the lungs</p>
        </div>
      ),
    },
    {
      shift: [{ x: -0.075, y: 1.448, z: -0.089 }],
      direction: { x: -0.115, y: 1.5, z: 0 },
      label: "Bronchioles",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.5, z: -0.1 },
      camera: { x: -0.1, y: 1.5, z: 0.63 },
      description: (
        <div>
          <h1>(6) Bronchioles</h1>
          <p>
            Each bronchus is divided into bronchioles inside each lung ending in
            alveoli (or air sacs).
          </p>
        </div>
      ),
    },
    {
      shift: [{ x: 0.112, y: 1.352, z: -0.135 }],
      direction: { x: 0.18, y: 1.39, z: -0.15 },
      label: "Alveoli",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.5, z: -0.1 },
      camera: { x: 0.4, y: 1.5, z: 0.5 },
      description: (
        <div>
          <h1>(7) Alveoli (or air sacs). </h1>
          <h2>Their function</h2>
          <p>
            They contain alveoli that are surrounded by network of blood
            capillaries to exchange the gases.
          </p>
        </div>
      ),
    },
    {
      shift: [{ x: -0.13, y: 1.35, z: -0.04 }],
      direction: { x: -0.175, y: 1.39, z: 0.006 },
      label: "lungs",
      // label: "Two Lungs",
      labelYShift: -1,
      camtarget: { x: 0, y: 1.5, z: -0.1 },
      camera: { x: -0.2, y: 1.4, z: 0.6 },
      description: (
        <div>
          <h1>(8) lungs:</h1>
          <ul>
            <li>
              The two lungs are found in the thoracic cavity (chest cavity).
            </li>
            <li>They are surrounded by the ribs (ribcage).</li>
            <li>
              Each bronchus is divided into bronchioles inside each lung ending
              in alveoli (or air sacs).
            </li>
          </ul>
        </div>
      ),
    },
  ],
  childs: [],
};

export default model;
