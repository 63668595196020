const sounds = {
    respi: require("../audio/structure.ogg"),
    Nose: require("../audio/Nose.ogg"),
    Pharynx: require("../audio/pharynx.ogg"),
    Trachea: require("../audio/trachea.ogg"),
    Epiglottis: require("../audio/epiglottis.ogg"),
    // Epiglottis: require("../audio/trachea.ogg"),
    Bronchi: require("../audio/bronchi.ogg"),
    // Bronchi: require("../audio/pharynx.ogg"),
    lungs: require("../audio/lungs.ogg"),
    // Bronchioles: require("../audio/Bronchioles.ogg"),
    Bronchioles: require("../audio/bronchioles.ogg"),
    // Alveoli: require("../audio/Alveoli.ogg"),
    Alveoli: require("../audio/alveoli.ogg"),

};

export default sounds;
