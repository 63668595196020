import * as THREE from "three";
import { putLine } from "./SceneObjects";
import { makeTextSprite, makeNumberSprite } from "./drawtext.js";
import { renderedItem } from "./Model";

function hasTargetChilds(targets) {
  if (targets !== undefined && targets.length > 0) {
    return true;
  } else {
    return false;
  }
}
function renderLabel(target, helperPosition, text, indextype) {
  const {
    labelYShift,
    color,
    label,
    description,
    openlabel,
    targets,
    small,
  } = target;
  let yshift = 0;
  const type = typeof text;

  if (type === "string" || openlabel === true) {
    var text2d = makeTextSprite(
      openlabel === true ? label : text,
      openlabel ? "#333" : "#fff",
      openlabel ? "#FFEA00dd" : color,
      [5, 2],
      "bold",
      1.5,
      "#fffe",
      target,
      small === true ? 0.7 : undefined
    );
    if (labelYShift) {
      let correctFactor = small === true ? 0.035 : 0.06;
      if(openlabel===true){
        correctFactor = 0.013
      }
      yshift =  correctFactor * labelYShift;
    }
  } else if (type === "number") {
    var text2d = makeNumberSprite(
      indextype === "alpha" ? String.fromCharCode(64 + text) : String(text + 1),
      hasTargetChilds(targets) ? "#FFEA00" : "white",
      false,
      [5, 5],
      "bold",
      0,
      hasTargetChilds(targets) ? "#FFEA00" : "#fffd",
      target,
      small === true ? 0.7 : undefined
    );
    if (labelYShift) {
      const correctFactor =
        small === true && target.openlabel !== true ? 0.0114 : 0.019;

      yshift = correctFactor * labelYShift;
    }
  }

  text2d.position.set(
    helperPosition.x,
    helperPosition.y - yshift,
    helperPosition.z
  );
  renderedItem.text.push(text2d);
  return text2d;
}

function renderArrows(exactPositions, helperPosition, color, sublines) {
  var labelsGroup = new THREE.Group();
  labelsGroup.name = "arrows";
  exactPositions.forEach((p) => {
    labelsGroup.add(putLine(p, helperPosition, color, sublines));
    // renderedItem.line.push(putLine(p, helperPosition));
  });
  return labelsGroup;
}

export { renderArrows, renderLabel };
