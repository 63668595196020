import extract from "../../../helpers/extract";
import * as THREE from "three";

function extractBones(root) {
  const skullBrain = extract(root, "SkullBrain");
  const Nervous_Cerebellum_Low = extract(root, "Nervous_Cerebellum_Low");
  const Spinal_Cord = extract(root, "Spinal_Cord");
  const Nervous_Nerves = extract(root, "Nervous_Nerves");
  const Skeletal_Mandible_Low = extract(skullBrain, "Nervous_Cerebrum_Low001");
  const Skeletal_Cranium_Low = extract(skullBrain, "Skeletal_Cranium_Low");
  const skull001 = extract(root, "Skull001");
  const Outer_Body = extract(root, "Outer_Body");

  Nervous_Nerves.material.color = new THREE.Color("#fc0");

  root.translateY(-0.37);
  root.translateZ(-0.125);
  Outer_Body.material.transparent = true;
  Outer_Body.material.opacity = 0.35;

  return {
    root,
    Skeletal_Cranium_Low,
    Skeletal_Mandible_Low,
    Outer_Body,
    skull001,
    Nervous_Cerebellum_Low,
    Spinal_Cord,
  };
}

export default extractBones;
