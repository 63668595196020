const sounds = {
  loca: require("../projects/loca/helpers/extractSounds"),
  nervous: require("../projects/nervous/helpers/extractSounds"),
  respi: require("../projects/respi/helpers/extractSounds"),
  digestive: require("../projects/digestive/helpers/extractSounds"),
  urin: require("../projects/urin/helpers/extractSounds"),
  circ: require("../projects/circ/helpers/extractSounds"),
  animal: require("../projects/animal/helpers/extractSounds"),
  plant: require("../projects/plant/helpers/extractSounds"),
};

const models = {
  loca: require("../projects/loca/model/model.glb"),
  nervous: require("../projects/nervous/model/model.glb"),
  respi: require("../projects/respi/model/model.glb"),
  digestive: require("../projects/digestive/model/model.glb"),
  urin: require("../projects/urin/model/model.glb"),
  circ: require("../projects/circ/model/model.glb"),
  animal: require("../projects/animal/model/model.glb"),
  plant: require("../projects/plant/model/model.glb"),
};

const data = {
  loca: require("../projects/loca/data/data"),
  nervous: require("../projects/nervous/data/data"),
  respi: require("../projects/respi/data/data"),
  digestive: require("../projects/digestive/data/data"),
  urin: require("../projects/urin/data/data"),
  circ: require("../projects/circ/data/data"),
  animal: require("../projects/animal/data/data"),
  plant: require("../projects/plant/data/data"),
};

const bonesExtractor = {
  loca: require("../projects/loca/helpers/extractBones"),
  nervous: require("../projects/nervous/helpers/extractBones"),
  respi: require("../projects/respi/helpers/extractBones"),
  digestive: require("../projects/digestive/helpers/extractBones"),
  urin: require("../projects/urin/helpers/extractBones"),
  circ: require("../projects/circ/helpers/extractBones"),
  animal: require("../projects/animal/helpers/extractBones"),
  plant: require("../projects/plant/helpers/extractBones"),
};

const animationExtractor = {
  loca: require("../projects/loca/helpers/extractAnimations"),
  nervous: {},
  respi: {},
  digestive: require("../projects/digestive/helpers/extractAnimations"),
  urin: require("../projects/circ/helpers/extractAnimations"),
  circ: require("../projects/circ/helpers/extractAnimations"),
  animal: {},
  plant: {},
};

export { sounds, models, data, bonesExtractor, animationExtractor };
