import React from "react";

const model = {
  label: "",
  id: "loca",
  light: {
    amp: 0.5,
    front: 0.8,
    back: 0.8,
  },
  label: "The Locomotory system",
  hidden: [],
  camtarget: { x: 0, y: 1, z: 0 },
  camera: { x: -0.2, y: 1.4, z: 2.5 },
  targets:[]
/* 
  targets: [
    {
      label: "the skeletal system",
      id: "skeletal",

      hidden: ["Heart", "Joints_Movement", "Muscular_System", "leftMusles"],

      targets: [],
      shift: [{ x: -0.04, y: 1.82, z: -0.03 }],
      direction: { x: -0.13, y: 1.85, z: -0.05 },
      labelYShift: -1,
      camera: { x: 0, y: 1.6, z: 1.9 },

      camtarget: { x: 0, y: 1.5, z: -0.05 },
      targets: [
        {
          label: "the skeletal system",
          id: "skeletal",
          description: (
            <div>
              <h1>The skeletal system</h1>
              <h2>It consists of:</h2>
              <ul>
                <li>Axial skeleton</li>
                <li>Appendicular skeleton</li>
              </ul>
            </div>
          ),
          shift: [{ x: -0.04, y: 1.82, z: -0.03 }],
          direction: { x: -0.13, y: 1.85, z: -0.05 },
          labelYShift: -1,
          openlabel: true,
          small: true,
        },
        {
          label: "Axial skeleton",
          id: "Axial",
          shift: [{ x: 0.02, y: 1.573, z: -0.1 }],
          direction: { x: 0.15, y: 1.633, z: -0.12 },
          labelYShift: -1,
          hidden: [
            "Heart",
            "Joints_Movement",
            "Muscular_System",
            "leftMusles",
            "leftarm",
            "lowerLimbs",
            "rightArm",
            "ribcageOuter",
            "eye",
          ],
          camtarget: { x: 0, y: 1.7, z: -0.05 },
          camera: { x: 0.2, y: 1.5, z: 1.9 },
          targets: [
            {
              label: "Axial skeleton",
              id: "Axial",
              shift: [{ x: -0.04, y: 1.82, z: -0.03 }],
              direction: { x: -0.13, y: 1.85, z: -0.05 },
              labelYShift: -1,
              description: (
                <div>
                  <h1>The axial skeleton</h1>
                  <h2>It consists of:</h2>

                  <ol className="alph">
                    <li>Skull</li>
                    <li>Backbone</li>
                    <li>Ribcage</li>
                  </ol>
                </div>
              ),
              camtarget: { x: 0, y: 1.4, z: -0.05 },
              camera: { x: 0.2, y: 1.5, z: 1.6 },
              openlabel: true,
            },
            {
              label: "The skull",
              id: "skull",
              shift: [{ x: 0.06, y: 1.8, z: -0.04 }],
              direction: { x: 0.17, y: 1.72, z: -0.04 },
              labelYShift: 1,
              description: (
                <div>
                  <h1>A) The skull</h1>
                  <p>
                    It is a bony bonx contains cavities for eyes, ears and nose.{" "}
                  </p>
                  <h2>Its function:</h2>
                  <p>It protects the brain.</p>
                </div>
              ),
              hidden: [
                "Heart",
                "Joints_Movement",
                "Muscular_System",
                "leftMusles",
                "lowerLimbs",
                "rightArm",
                "leftarm",
                "ribcageOuter",
                "eye",
                "ribcage",
                "backbone",
                "immovableJoints",
              ],

              targets: [
                {
                  shift: [{ x: 0.06, y: 1.8, z: -0.04 }],
                  direction: { x: 0.17, y: 1.72, z: -0.04 },
                  label: "The skull",
                  labelYShift: 1,
                },
              ],
              camtarget: { x: 0, y: 1.7, z: -0.05 },

              camera: { x: 0.4, y: 1.7, z: 0.5 },
              childs: [],
            },
            {
              label: "The backbone",
              id: "backbone",
              shift: [{ x: 0.01, y: 1.093, z: -0.08 }],
              direction: { x: 0.14, y: 1.03, z: -0.08 },
              labelYShift: 1,
              description: (
                <div>
                  <h1>B) The backbone</h1>
                  <p>
                    It consists of 33 vertebrae with cartilages between them.
                  </p>
                  <h2>Why?</h2>
                  <p>To prevent the friction between them.</p>
                  <h2>Its functions:</h2>
                  <ol>
                    <li>It protects the spinal cord.</li>
                    <li>It allows the body to bend in difeerent directions.</li>
                  </ol>
                </div>
              ),
              hidden: [
                "Heart",
                "Joints_Movement",
                "Muscular_System",
                "leftMusles",
                "lowerLimbs",
                "rightArm",
                "leftarm",
                "ribcageOuter",
                "eye",
                "ribcage",
                "skull",
                "immovableJoints",
              ],
              targets: [
                {
                  shift: [{ x: 0, y: 1.15, z: -0.08 }],
                  direction: { x: -0.11, y: 1.25, z: -0.08 },
                  label: "Cartilage",
                  labelYShift: -1,
                },
                {
                  shift: [{ x: 0.01, y: 1.2, z: -0.1 }],
                  direction: { x: 0.12, y: 1.28, z: -0.1 },
                  label: "Vertebra",
                  labelYShift: -1,
                },
              ],
              camtarget: { x: 0, y: 1.3, z: -0.05 },
              camera: { x: 0.6, y: 1.5, z: 1 },
              childs: [],
            },
            {
              label: "The ribcage",
              id: "ribcage",
              shift: [{ x: 0.13, y: 1.353, z: -0.03 }],
              direction: { x: 0.24, y: 1.273, z: -0.02 },
              labelYShift: 1,
              description: (
                <div>
                  <h1>C) Ribcage</h1>
                  <p>
                    It consists of 12 pairs of ribs and the first 10 ribs are
                    connected anteriorly to the "Sternum".
                  </p>
                  <h2>Its functions:</h2>
                  <ol>
                    <li>It protects The hert and lungs.</li>
                    <li>It helps in inhalation and exhalation.</li>
                  </ol>
                </div>
              ),
              hidden: [
                "Heart",
                "Joints_Movement",
                "Muscular_System",
                "leftMusles",
                "lowerLimbs",
                "rightArm",
                "leftarm",
                "ribcageOuter",
                "eye",
                "skull",
                "immovableJoints",
                "backbone",
              ],

              targets: [
                {
                  shift: [
                    { x: -0.09, y: 1.45, z: -0.041 },
                    { x: -0.08, y: 1.38, z: 0.005 },
                  ],
                  direction: { x: -0.16, y: 1.5, z: 0.008 },
                  label: "Ribs",
                  labelYShift: -1,
                },
                {
                  shift: [{ x: -0.01, y: 1.39, z: 0.002 }],
                  direction: { x: 0.1, y: 1.46, z: 0.05 },
                  label: "Sternum",
                  labelYShift: -1,
                },
              ],
              camtarget: { x: 0, y: 1.3, z: -0.05 },
              camera: { x: -0.5, y: 1.6, z: 0.8 },
              childs: [],
            },
          ],
        },

        {
          label: "Appendicular skeleton",
          id: "Appendicular",
          shift: [{ x: -0.32, y: 1.463, z: -0.17 }],
          direction: { x: -0.39, y: 1.553, z: -0.12 },
          description: (
            <div>
              <h1>Appendicular skeleton</h1>
              <h2>It consists of:</h2>
              <ol className="alph">
                <li>Bones of upper limbs.</li>
                <li>bones of lower limbs.</li>
              </ol>
            </div>
          ),
          hidden: ["Heart", "Joints_Movement", "Muscular_System", "leftMusles"],

          targets: [
            {
              shift: [
                { x: -0.145, y: 0.12, z: -0.07 },
                { x: -0.125, y: 0.33, z: -0.03 },
                { x: -0.125, y: 0.78, z: -0.06 },
              ],
              direction: { x: -0.39, y: 0.14, z: -0.03 },
              label: "Bones of \n lower limbs",
              labelYShift: 1,
            },
            {
              shift: [
                { x: -0.33, y: 1.46, z: -0.17 },
                { x: -0.53, y: 1.435, z: -0.15 },
                { x: -0.69, y: 1.425, z: -0.067 },
              ],
              direction: { x: -0.44, y: 1.23, z: -0.107 },
              label: "Bones of \n upper limbs",
              labelYShift: 1,
            },
          ],
          camtarget: { x: 0, y: 0.9, z: -0.05 },
          camera: { x: 0.4, y: 1.6, z: 2.7 },

          childs: [
            {
              label: "Bones of upper limbs",
              id: "upperlimb",
              description: (
                <div>
                  <h1>A) Bones of upper limbs</h1>
                  <h2>They consist of:</h2>
                  <ul>
                    <li>Humerus bones.</li>
                    <li>Forearm bones.</li>
                    <li>hand bones.</li>
                  </ul>
                  <p>They are connected with shoulder bones.</p>
                  <h2>Their function:</h2>
                  <p>
                    The upper limbs help in eating, drinking and holding things.
                  </p>
                </div>
              ),
              hidden: [
                "Heart",
                "Joints_Movement",
                "Muscular_System",
                "leftMusles",
                "lowerLimbs",
                "immovableJoints",
                "eye",
              ],

              targets: [
                {
                  shift: [
                    { x: -0.09, y: 1.42, z: -0.19 },
                    { x: -0.17, y: 1.518, z: -0.15 },
                  ],
                  direction: { x: -0.22, y: 1.648, z: -0.25 },
                  label: "Shoulder bones",
                  labelYShift: -1,
                },
                {
                  shift: [{ x: -0.33, y: 1.458, z: -0.17 }],
                  direction: { x: -0.33, y: 1.54, z: -0.17 },
                  label: "Humerus",
                  labelYShift: -1,
                },
                {
                  shift: [
                    { x: -0.58, y: 1.432, z: -0.13 },
                    { x: -0.58, y: 1.451, z: -0.096 },
                  ],
                  direction: { x: -0.58, y: 1.571, z: -0.136 },
                  label: "Forearm bones",
                  labelYShift: -1,
                },
                {
                  shift: [{ x: -0.684, y: 1.435, z: -0.058 }],
                  direction: { x: -0.684, y: 1.52, z: -0.058 },
                  label: "Hand bones",
                  labelYShift: -1,
                },
              ],
              camtarget: { x: -0.2, y: 1.35, z: -0.05 },
              camera: { x: -1.4, y: 1.8, z: -1.3 },
              childs: [],
            },
            {
              label: "Bones of lower limbs",
              id: "lowerlimb",
              description: (
                <div>
                  <h1>B) Bones of lower limbs</h1>
                  <h2>They consist of:</h2>
                  <ul>
                    <li>Fermur bones.</li>
                    <li>Shaft bones.</li>
                    <li>Foot bones.</li>
                  </ul>
                  <p>They are connected to pelvic bones.</p>
                  <h2>Their function:</h2>
                  <p>
                    they help in walking, running, sitting and carry all the
                    body parts.
                  </p>
                </div>
              ),
              hidden: [
                "Heart",
                "Joints_Movement",
                "upperLimbs",
                "Muscular_System",
                "eye",
                "leftMusles",
                "immovableJoints",
              ],

              targets: [
                {
                  shift: [{ x: 0.15, y: 0.04, z: -0.05 }],
                  direction: { x: 0.218, y: 0.25, z: 0 },
                  label: "Foot bones",
                  labelYShift: -1,
                },
                {
                  shift: [
                    { x: -0.15, y: 0.28, z: -0.065 },
                    { x: -0.13, y: 0.37, z: -0.025 },
                  ],
                  direction: { x: -0.3, y: 0.26, z: -0.045 },
                  label: "Shaft bones",
                  labelYShift: 1,
                },
                {
                  shift: [{ x: -0.12, y: 0.72, z: -0.045 }],
                  direction: { x: -0.27, y: 0.65, z: -0.045 },
                  label: "Fermur",
                  labelYShift: 1,
                },
                {
                  shift: [{ x: -0.126, y: 1.06, z: -0.085 }],
                  direction: { x: -0.26, y: 0.94, z: -0.095 },
                  label: "Plevic bones",
                  labelYShift: 1,
                },
              ],
              camtarget: { x: 0, y: 0.5, z: -0.05 },
              camera: { x: -0.8, y: 1.4, z: 1.5 },
              childs: [],
            },
          ],
        },
      ],
    },
    /* 
    {
      label: "The muscular system",
      id: "muscularsys",
      description: (
        <div>
          <h1>The muscular system</h1>
          <ul>
            <li>
              The muscles contract and relax to generate mechanical energy and
              the ability of the body to move.
            </li>
          </ul>

          <p>
            Muscles are fixed to bones by strips called <b>tendons</b>.
          </p>
        </div>
      ),
      hidden: ["Heart", "Joints_Movement", "leftMusles", "immovableJoints"],
      shift: [{ x: -0.06, y: 1.18, z: -0.12 }],
      direction: { x: -0.21, y: 1.11, z: -0.03 },
      labelYShift: 1,
      targets: [
        {
          shift: [
            { x: -0.12, y: 0.53, z: 0.022 },
            { x: -0.16, y: 0.52, z: -0.04 },
          ],
          direction: { x: -0.28, y: 0.45, z: 0 },
          label: "Tendons",
          color: "g",
          labelYShift: 1,
        },
      ],
      camtarget: { x: 0, y: 0.9, z: -0.05 },
      camera: { x: -0.5, y: 1.6, z: 2.7 },
      childs: [
        {
          label: "Voluntary muscles",
          id: "voluntary",
          description: (
            <div>
              <h1>1) Voluntary muscles</h1>
              <p>
                That we can move it willingly such as muscles of (limbs, trunk,
                face and abdominal wall muscles).
              </p>
            </div>
          ),
          hidden: [
            "Heart",
            "Joints_Movement",
            "ribcage",
            "ribcageOuter",
            "backbone",
            "leftarm",
            "rightArm",
            "lowerLimbs",
          ],

          targets: [
            {
              shift: [
                { x: -0.052, y: 1.65, z: -0.005 },
                { x: -0.022, y: 1.624, z: 0.028 },
              ],
              direction: { x: -0.2, y: 1.584, z: 0.028 },
              label: "Face Muscles",
              labelYShift: 1,
            },
          ],
          camtarget: { x: 0, y: 1, z: -0.05 },
          camera: { x: -1, y: 1.7, z: 2 },
          childs: [],
        },
        {
          label: "involuntary muscles",
          id: "involuntary",
          animated: true,
          description: (
            <div>
              <h1>2) Involuntary muscles</h1>
              <p>
                They work automatically and we can't control them such as
                bladder muscles, heartbeats.
              </p>
            </div>
          ),
          hidden: ["Body", "Joints_Movement"],
          show: ["Heart"],

          camtarget: { x: 0, y: 1.4, z: -0.01 },
          targets: [
            {
              shift: [{ x: 0.03, y: 1.43, z: 0.05 }],
              direction: { x: 0.06, y: 1.51, z: -0.01 },
              label: "Heart",
              color: "g",
              labelYShift: -1,
            },
          ],
          camera: { x: 0.2, y: 1.6, z: 0.4 },
          childs: [],
        },
      ],
    },
    {
      label: "Joints Movement",
      id: "jointmovement",
      type: "s",
      animated: true,
      description: (
        <div>
          <h1>Joints Movement</h1>
          <p>It is the location at which bones meet together.</p>
          <h2>Its function:</h2>
          <p>They allow movement between bones.</p>
          <p>
            There are <b>three</b> types of joints which are:
          </p>
          <ol>
            <li>Immovable joints.</li>
            <li>Slightly movable joints.</li>
            <li>Freely movable joints.</li>
          </ol>
        </div>
      ),
      hidden: ["Heart", "Body"],
      show: ["Joints_Movement"],

      camtarget: { x: -0.3, y: 0.55, z: 0.1 },
      targets: [],

      camera: { x: -0.8, y: 0.3, z: 0.7 },
      childs: [
        {
          label: "immovable joints",
          id: "immovable",
          description: (
            <div>
              <h1>1) Immovable joints</h1>
              <p>
                That don't allow any movement such as that between skull bones.
              </p>
            </div>
          ),
          hidden: [
            "Heart",
            "Joints_Movement",
            "Muscular_System",
            "leftMusles",
            "lowerLimbs",
            "rightArm",
            "leftarm",
            "ribcageOuter",
            "eye",
            "ribcage",
            "backbone",
          ],

          targets: [
            {
              shift: [
                { x: 0.03, y: 1.817, z: -0.014 },
                { x: -0.008, y: 1.827, z: -0.032 },
              ],
              direction: { x: 0.012, y: 1.89, z: -0.022 },
              label: "Joints of skull",
              labelYShift: -1,
              color: "b",
            },
          ],
          camtarget: { x: 0, y: 1.7, z: -0.05 },
          camera: { x: 0.4, y: 2.2, z: 0.3 },
          childs: [],
        },
        {
          label: "Slightly movable joints",
          id: "Slightlymovable",
          animated: true,
          description: (
            <div>
              <h1>2) Slightly movable joints</h1>
              <p>
                That allow movement in one direction only such as the knee and
                elbow joints.
              </p>
            </div>
          ),
          hidden: [
            "leftMusles",
            "leftarm",
            "Heart",
            "Joints_Movement",
            "Muscular_System",
            "lowerLimbs",
            "ribcageOuter",
            "eye",
            "ribcage",
            "skull",
            "immovableJoints",
            "backbone",
          ],
          camtarget: { x: -0.21, y: 1.19, z: -0.14 },
          camera: { x: -0.9, y: 1.5, z: 1 },
          targets: [
            {
              shift: [{ x: -0.21, y: 1.19, z: -0.14 }],
              direction: { x: -0.4, y: 1, z: -0.14 },
              label: "Elbow joint",
              labelYShift: 1,
              color: "b",
            },
          ],

          childs: [],
        },
        {
          label: "Freely movables joints",
          id: "freelymovable",
          animated: true,
          description: (
            <div>
              <h1>3) Freely movable joints</h1>
              <p>
                That allow the movement in all direction such as shoulder,
                wrist, ankle and thigh joints.
              </p>
            </div>
          ),
          hidden: [
            "leftMusles",
            "leftarm",
            "Heart",
            "Joints_Movement",
            "Muscular_System",
            "lowerLimbs",
            "ribcageOuter",
            "eye",
            "ribcage",
            "skull",
            "immovableJoints",
            "backbone",
          ],

          camtarget: { x: -0.23, y: 1.202, z: -0.09 },
          camera: { x: -0.8, y: 2, z: 1 },
          targets: [
            {
              shift: [{ x: -0.2, y: 1.212, z: 0.12 }],
              direction: { x: -0.3, y: 1.1, z: 0.12 },
              label: "Writs joint",
              labelYShift: 1,
              color: "b",
            },
          ],

          childs: [],
        },
      ],
    },
    {
      label: "Muscles Movement",
      id: "MusclesMovement",
      description: (
        <div>
          <h1>the role of muscles in the movement of the forearm</h1>
          <ol>
            <li>
              When the front muscle of humerus contracts, while the back muscle
              relaxes, the forearm moves toward the humerus.
            </li>
            <li>
              When the front muscle of humerus relaxes while the back muscle
              contracts, the humerus moves away from the forearm.
            </li>
          </ol>
        </div>
      ),
      hidden: [
        "Heart",
        "Joints_Movement",
        "Muscular_System",
        "lowerLimbs",
        "rightArm",
        "ribcageOuter",
        "eye",
        "ribcage",
        "skull",
        "immovableJoints",
        "backbone",
      ],
      animated: true,
      type: "s",
      camtarget: { x: 0.2, y: 1.29, z: -0.13 },
      targets: [
        {
          shift: [{ x: 0.18, y: 1.36, z: -0.105 }],
          direction: { x: 0, y: 1.24, z: 0.055 },
          label: "Front Muscles",
          labelYShift: 1,
          color: "b",
        },
        {
          shift: [{ x: 0.2, y: 1.34, z: -0.15 }],
          direction: { x: 0.37, y: 1.16, z: -0.12 },
          label: "Back Muscles",
          labelYShift: 1,
          color: "b",
        },
      ],
      camera: { x: 1.3, y: 2, z: 0.5 },
      childs: [],
    },
  ],
*/
};

export default model;
