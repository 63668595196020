import extract from "../../../helpers/extract";

function extractBones(root) {
  console.log(root);
  const Heart_Cutaway_parent = extract(root, "Heart_Cutaway");
  const Plane001 = extract(Heart_Cutaway_parent,"Plane001")
  const Plane002 = extract(Heart_Cutaway_parent,"Plane002")
  const Plane003 = extract(Heart_Cutaway_parent,"Plane003")
  const Plane004 = extract(Heart_Cutaway_parent,"Plane004")
  const Plane005 = extract(Heart_Cutaway_parent,"Plane005")
  const Plane016 = extract(Heart_Cutaway_parent,"Plane016")

  const Heart_Cutaway = extract(Heart_Cutaway_parent, "Heart");

  const Heart_Complit_parent = extract(root, "Heart_Complit");
  const Heart_Complit_u = extract(Heart_Complit_parent, "Circulatory_Heart004");
  const Heart_Complit_l = extract(Heart_Complit_parent, "Circulatory_Veins002");

  const Blood_Capillaries_parent = extract(root, "Blood_Capillaries");
  const Blood_Capillaries_wires_parent = extract(
    Blood_Capillaries_parent,
    "Frame002"
  );

  const Blood_Capillaries_wires = extract(
    Blood_Capillaries_wires_parent,
    "Frame002_0"
  );
  const Blood_Capillaries_wires_b = extract(
    Blood_Capillaries_wires_parent,
    "Frame002_1"
  );
  const Blood_Capillaries_wires_r = extract(
    Blood_Capillaries_wires_parent,
    "Frame002_2"
  );

  const Blood_Capillaries_parent2 = extract(Blood_Capillaries_parent, "Sphere");
  const Blood_Capillaries_0 = extract(Blood_Capillaries_parent2, "Sphere_0");
  const Blood_Capillaries_1 = extract(Blood_Capillaries_parent2, "Sphere_1");

  const Bplane = extract(Heart_Cutaway_parent, "Plane001");

  const Red_blood_cells = extract(root, "Red_blood_cell");
  const Red_blood_cell = extract(Red_blood_cells, "R001");

  const White_blood_cell = extract(root, "White_blood_cell");

  const Blood_platelets = extract(root, "Blood_platelets");

  const Plasma = extract(root, "Plasma");

  const Involuntary_Blood_Muscles = extract(root, "Involuntary_Blood_Muscles");

  Heart_Cutaway_parent.position.setX(-1);
  Heart_Cutaway_parent.position.setZ(-1);
  Heart_Complit_parent.position.setX(-1);
  Heart_Complit_parent.position.setX(-1);
  Blood_Capillaries_parent.position.setX(0.5);

  return {
    root,
    Bplane,
    Heart_Cutaway,
    Heart_Complit_l,
    Heart_Complit_u,
    Blood_Capillaries_0,
    Blood_Capillaries_1,
    Red_blood_cell,
    Red_blood_cells,
    Blood_Capillaries_wires,
    Blood_Capillaries_wires_r,
    Blood_Capillaries_wires_b,
    Blood_Capillaries_parent,
    Heart_Cutaway_parent,
    Involuntary_Blood_Muscles,
    Plasma,
    White_blood_cell,
    Blood_platelets,
    Heart_Complit_parent,
    Plane001,
    Plane002,
    Plane003,
    Plane004,
    Plane005,
    Plane016,
  };
}

export default extractBones;
