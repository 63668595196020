// TODO 3/11 Remove Mouth white label after exiting layer
// TODO 3/11 Make raycasting like layers (Prevent responding to hidden layers)
// we have to methods
// 1- remove the hidden layers and recreate again
// 2- intersections in layers [something like active layers] it will be a variable in the raycaster and only listen to it
//  it will change only when we change the active layer throw handleNavigation...etc

import * as THREE from "three";
import { scene, controls } from "./setup.js";
import { loadModel } from "./ModelLoader";
import {
  bonesExtractor,
  animationExtractor,
  models,
} from "../helpers/getFiles";
import { renderArrows, renderLabel } from "./ModelRenderHelper";

import {
  animateCamTarget,
  animateCamera,
  handleModelAnimations,
  animateUV,
  stopAnimation,
} from "./Animate";
import projectName from "../helpers/projectName";

import { loadAudio, stopSound } from "../components/audio";
import { getExactPosition } from "./helpers";
import {
  putSphere,
  addCustomSceneObjects,
  changeLightInt,
} from "./SceneObjects";
import extract from "../helpers/extract";
//LocaTextures^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
import Bones_normal from "../projects/loca/textures/Bones_normal.jpg";
import Heart_diffuse_N from "../projects/loca/textures/Heart_diffuse_N.jpg";
import Heart2_diffuse_N from "../projects/loca/textures/Heart2_diffuse_N.jpg";
import KneeC_N from "../projects/loca/textures/KneeC_N.jpg";
import MuscularSystem_Normal from "../projects/loca/textures/MuscularSystem_Normal.jpg";

import Skeletal_NormalMap from "../projects/loca/textures/Skull_normal.jpg";
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

//RespiTextures^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
import DigestiveSystem_LowNormalsMap from "../projects/respi/textures/DigestiveSystem_LowNormalsMap.jpg";
import Respiratory_System_LowNormalsMap from "../projects/respi/textures/Respiratory_System_LowNormalsMap.jpg";
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

//CircTextures^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
import Diffuse from "../projects/circ/textures/Diffuse_N.jpg";
import Heart_diffusess from "../projects/circ/textures/Heart_diffuse_N.jpg";
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
import { data } from "../helpers/getFiles";

const projectdata = data[projectName].default;

var labelsGroup = new THREE.Group();
labelsGroup.name = "indexLables";
scene.add(labelsGroup);

var textureLoader = new THREE.TextureLoader();

let setrightToggle,
  setdescription,
  setActiveID,
  setDisplayAudioButton,
  setplayAudio,
  setNavigationItems,
  NavigationItems;

let mixer = undefined;
let bones = undefined;
let animations = undefined;
let renderedItem = {
  text: [],
  line: [],
  hiddenRigs: [],
  animation: undefined,
  label: undefined,
};

function updateNavigationItems(item) {
  NavigationItems = item;
}

function createModel(loadingListener, UIControllers) {
  setrightToggle = UIControllers.setrightToggle;
  setdescription = UIControllers.setdescription;
  setActiveID = UIControllers.setActiveID;
  setNavigationItems = UIControllers.setNavigationItems;
  NavigationItems = UIControllers.NavigationItems;

  setDisplayAudioButton = UIControllers.setDisplayAudioButton;
  setplayAudio = UIControllers.setplayAudio;
  const filepath = models[projectName];
  const extractBones = bonesExtractor[projectName].default;
  const extractAnimations = animationExtractor[projectName].default;

  loadModel(loadingListener, filepath, projectName)
    .then((gltf) => {
      // putSphere(new THREE.Vector3(0, 0, 0));
      bones = extractBones(gltf.scene);
      if (gltf.animations.length > 0) {
        if (extractAnimations !== undefined) {
          animations = extractAnimations(gltf);
          mixer = new THREE.AnimationMixer(gltf.scene);
        }
      }
      if (projectName === "nervous") {
        /* 220 185 */
      } else if (projectName === "loca") {
        //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
        var Skeletal_NormalMap2 = textureLoader.load(Skeletal_NormalMap);
        bones.skull.material.normalMap = Skeletal_NormalMap2;
        bones.skull.material.normalMap.flipY = false;
        bones.skull.material.roughness = 0.5;
        bones.skull.material.reflectivity = 0.2;

        var MuscularSystem_Normal2 = textureLoader.load(MuscularSystem_Normal);
        bones.Muscular_System.material.normalMap = MuscularSystem_Normal2;
        bones.Muscular_System.material.normalMap.flipY = false;
        bones.Muscular_System.material.roughness = 0.8;
        bones.Muscular_System.material.reflectivity = 0.2;

        bones.leftMusles.material.normalMap = MuscularSystem_Normal2;
        bones.leftMusles.material.normalMap.flipY = false;

        var Bones_normal2 = textureLoader.load(Bones_normal);
        bones.ribcage.material.normalMap = Bones_normal2;
        bones.ribcage.material.normalMap.flipY = false;
        bones.ribcage.material.roughness = 0.5;
        bones.ribcage.material.reflectivity = 0.2;
        bones.ribcage.material.emissiveIntensity = 2.5;

        var KneeC_N2 = textureLoader.load(KneeC_N);
        bones.Joints_Movement_muscles.material.normalMap = KneeC_N2;
        bones.Joints_Movement_muscles.material.normalMap.flipY = false;

        var Heart_diffuse_N2 = textureLoader.load(Heart_diffuse_N);
        bones.lowerHeart.material.normalMap = Heart_diffuse_N2;
        bones.lowerHeart.material.normalMap.flipY = false;

        var Heart2_diffuse_N2 = textureLoader.load(Heart2_diffuse_N);
        bones.upperHeart.material.normalMap = Heart2_diffuse_N2;
        bones.upperHeart.material.normalMap.flipY = false;

        // // var Joints_Movement_bones2 = textureLoader.load(KneeC_N2);
        bones.Joints_Movement_bones.material.normalMap = KneeC_N2;
        bones.Joints_Movement_bones.material.normalMap.flipY = false;

        bones.semana.material.normalMap = KneeC_N2;
        bones.semana.material.normalMap.flipY = false;

        bones.immovableJoints.scale.set(1.005, 1.005, 1.005);
        bones.Joints_Movement.visible = false;
        bones.Heart.visible = false;

        // bones.
      } else if (projectName === "respi") {
        bones.outerBody.material.transparent = false;
        bones.outerBody.material.opacity = 0.2;
        // bones.outerBody.material.wireframe = true;
        bones.outerBody.material.reflectivity = 0;

        bones.lungs.material.transparent = true;
        // bones.lungs.material.opacity = 1.5;

        var gidsysNormals = textureLoader.load(DigestiveSystem_LowNormalsMap);
        bones.stomach.material.normalMap = gidsysNormals;
        bones.stomach.material.normalMap.flipY = false;

        var respisysNormals = textureLoader.load(
          Respiratory_System_LowNormalsMap
        );
        bones.shoab.material.normalMap = respisysNormals;
        bones.shoab.material.normalMap.flipY = false;

        bones.lungs.material.normalMap = respisysNormals;
        bones.lungs.material.normalMap.flipY = false;

        bones.lungs.material.normalScale = new THREE.Vector2(0.8, -0.8);
        bones.lungs.material.reflectivity = 0.25;
        bones.lungs.material.roughness = 0.25;

        bones.lungs.material.depthTest = false;
        bones.lungs.material.depthWrite = false;
        // bones.lungs.material.transparent = false;

        bones.shoab.material.normalScale = new THREE.Vector2(0.5, -0.5);
        bones.shoab.material.reflectivity = 0.25;
        bones.shoab.material.roughness = 0.25;

        bones.stomach.material.normalScale = new THREE.Vector2(0.5, -0.5);
        bones.stomach.material.reflectivity = 0.25;
        bones.stomach.material.roughness = 0.25;
      } else if (projectName === "digestive") {
        controls.minDistance = projectdata.mincamerazoom;
        bones.stomach.material.metalness = 0.05;
        bones.stomach.material.reflectivity = 0.6;
        bones.stomach.material.roughness = 0.53;
        bones.stomach.material.normalScale = new THREE.Vector2(0.7, -0.7);

        bones.Skeletal_Cranium_Low.material.metalness = 0;
        bones.Skeletal_Cranium_Low.material.reflectivity = 0.65;
        bones.Skeletal_Cranium_Low.material.roughness = 0.6;
        bones.Skeletal_Cranium_Low.material.normalScale = new THREE.Vector2(
          1,
          -1
        );
      } else if (projectName === "urin") {
        controls.minDistance = projectdata.mincamerazoom;

        bones.Bplane.material.map.repeat = new THREE.Vector2(1, 7);
        // Skeletal_System_Low

        bones.Skeletal_System_Low.material.normalScale = new THREE.Vector2(
          1,
          -1
        );
        bones.Skeletal_System_Low.material.reflectivity = 0.25;
        bones.Skeletal_System_Low.material.roughness = 0.5;

        // bones.UrinarySystem.material.normalScale = new THREE.Vector2(1, -1);
        bones.UrinarySystem.material.reflectivity = 0.25;
        bones.UrinarySystem.material.roughness = 0.25;

        var Bones_normal2 = textureLoader.load(Bones_normal);
        bones.Skeletal_System_Low.material.normalMap = Bones_normal2;
        bones.Skeletal_System_Low.material.normalMap.flipY = false;

        // Bones_normal
        animateUV({ name: "arrow", target: bones.Bplane });
      } else if (projectName === "circ") {
        controls.minDistance = projectdata.mincamerazoom;

        bones.Bplane.material.map.repeat = new THREE.Vector2(1, 10);
        // Diffuse

        //         import Diffuse from "../projects/circ/textures/Diffuse_N.jpg";
        // import Heart_diffuse from "../projects/circ/textures/Heart_diffuse_N.jpg";

        var Heart_Cutaway_NN = textureLoader.load(Diffuse);
        bones.Heart_Cutaway.material.normalMap = Heart_Cutaway_NN;
        bones.Heart_Cutaway.material.normalMap.flipY = false;

        var Heart_diffuse = textureLoader.load(Heart_diffusess);
        // bones.Heart_Complit_l.material.normalMap = Heart_diffuse;
        // bones.Heart_Complit_l.material.normalMap.flipY = false;

        bones.Heart_Complit_u.material.normalMap = Heart_diffuse;
        bones.Heart_Complit_u.material.normalMap.flipY = false;
        // bones.Heart_Complit_u.material.wireframe = true;
        // bones.Blood_Capillaries.material.metalness=0;

        bones.Blood_Capillaries_0.material.metalness = 0;
        bones.Blood_Capillaries_1.material.metalness = 0;
        bones.Blood_Capillaries_wires.material.metalness = 0;
        bones.Blood_Capillaries_wires_r.material.metalness = 0;
        bones.Blood_Capillaries_wires_b.material.metalness = 0;
        bones.Red_blood_cell.material.metalness = 0.2;
        animateUV({ name: "plasma", target: bones.Plasma });

        // Blood_Capillaries_1

        // Heart_Cutaway,
        // Heart_Complit,
        // Blood_Capillaries,
        animateUV({ name: "arrow", target: bones.Bplane });
      } else if (projectName === "animal") {
        console.log("ANIMALLLLL");
      } else if (projectName === "plant") {
        console.log("planttt");
      }
      scene.add(gltf.scene);
      console.log("HACKKKK", projectdata);
      handleItemClick(projectdata);
    })
    .catch((err) => console.log("can not load the model", err));
}

function clearItems() {
  const { hiddenRigs } = renderedItem;
  hiddenRigs.forEach((h) => {
    bones[h].visible = true;
  });
}

function hideBodyParts(hiddenRigs) {
  if (hiddenRigs !== undefined) {
    clearItems();
    hiddenRigs.forEach((hidden) => {
      bones[hidden].visible = false;
    });
    renderedItem.hiddenRigs = hiddenRigs;
  }
}

function ShowHiddenPart(showenRigs) {
  showenRigs.forEach((show) => {
    bones[show].visible = true;
  });
}

function renderItem(targets, type, name) {
  var indexGroupParent = new THREE.Group();
  indexGroupParent.name = name;

  targets.forEach((target, index) => {
    const { helperPosition, exactPositions } = getExactPosition(target);
    let renderedArrows = renderArrows(
      exactPositions,
      helperPosition,
      target.color,
      target.sublines
    );

    let renderedLabel = renderLabel(target, helperPosition, index, type);
    var indexGroup = new THREE.Group();
    indexGroup.name = target.label;

    indexGroup.add(renderedLabel);
    indexGroup.add(renderedArrows);

    // labelsGroup.add(renderTextLabel);
    // renderedItem.label = renderTextLabel;
    indexGroupParent.add(indexGroup);
    labelsGroup.add(indexGroupParent);
  });
  return indexGroupParent;
}

// Run once fff refactor it later
function handleItemClick(item) {
  const {
    id,
    targets,
    camera,
    hidden,
    camtarget,
    animated,
    show,
    label,
    light,
  } = item;

  RemoveAllLayers(false);
  changeLightInt(light);
  if (animated) {
    handleModelAnimations(animated, "involuntary", animations);
  }
  if (id !== renderedItem.id) {
    renderedItem.id = id;
    if (bones !== undefined) {
      hideBodyParts(hidden);
      if (show) {
        ShowHiddenPart(show);
      }

      renderItem(targets, "numbers", label);
    } else {
      console.log("ERRRRRRRROR, MODEL NOT LOADED YET");
    }

    animateCamTarget(camtarget);
    animateCamera(camera);
  } else {
    console.log("errrrror , already rendered");
  }
}

function RemoveAllLayers(stopAnim = true) {
  const indexLables = extract(scene, "indexLables");
  console.log("hey ", indexLables);
  if (indexLables) {
    indexLables.children.forEach((layer) => {
      indexLables.remove(layer);
    });
  }

  // create new layer instead
  // extract(indexLables, layerName).visible = true;
  if (stopAnim === true) {
    stopAnimation();
  }
  // return extract(indexLables, NavigationItems[NavigationItems.length-1]);
}

function removeLable() {
  if (renderedItem.label !== undefined) {
    const { parent } = renderedItem.label;
    if (parent !== null) {
      parent.remove(renderedItem.label);
    }
  }
}

function handleNavigationSwitch(layerName) {
  RemoveAllLayers();
  const { targets, label, hidden } = layerName;
  renderItem(targets, "numbers", label);
  hideBodyParts(hidden);
  removeLable();
}

function handleSpriteClick(target, theObject) {
  const {
    camtarget,
    camera,
    animated,
    label,
    targets,
    openlabel,
    hidden,
  } = target;
  stopSound(setDisplayAudioButton, setplayAudio);
  setrightToggle(true); //close description screen

  setActiveID(label);
  handleModelAnimations(animated, label, animations);
  animateCamTarget(camtarget);
  animateCamera(camera);

  hideBodyParts(hidden);

  // hide text label if existed
  if (renderedItem.label !== undefined) {
    removeLable();
  }

  if (targets !== undefined) {
    renderSubTargets(target, targets, labelsGroup);
  } else if (openlabel !== true) {
    renderTextLable(theObject, CreateLabels(target));
  }
}

function renderSubTargets(target, targets, labelsGroup) {
  const { label } = target;
  let oldNavigationItems = [...NavigationItems];
  console.log("OLDLLLLLLLLLLLLOLOLD", oldNavigationItems);

  oldNavigationItems.push(target);
  NavigationItems = oldNavigationItems;
  setNavigationItems(oldNavigationItems);

  labelsGroup.children.forEach((l) => {
    if (label !== l.name) {
      // l.visible = false;
      labelsGroup.remove(l);
    }
  });
  const indexLables = extract(scene, "indexLables");
  const ifExisted = indexLables.children.findIndex(
    (child) => child.name === "Mouth"
  );
  if (ifExisted === -1) {
    renderItem(targets, "alpha", label);
  } else {
    // indexLables.children[ifExisted].visible = true;
  }
}

function CreateLabels(target) {
  if (renderedItem.label !== undefined) {
    labelsGroup.remove(renderedItem.label);
  }
  const { direction, label, labelYShift, description } = target;
  target.labelYShift = labelYShift > 0 ? 1 : -1;
  target.color = "#111111aa";

  let renderTextLabel = renderLabel(
    target,
    new THREE.Vector3(direction.x, direction.y, direction.z),
    label
  );
  return renderTextLabel;
}

function renderTextLable(theObject, renderTextLabel) {
  // ATTACHING THE LABEL ONCE:
  if (theObject !== undefined) {
    const oldNumberLabel = theObject.parent.children.find((p) => p.name === 10);

    if (oldNumberLabel === undefined) {
      theObject.parent.add(renderTextLabel);
      renderedItem.label = renderTextLabel;
    }
  }
}

function handelLableClick(target) {
  setrightToggle(false);
  setdescription(target.description);
  setDisplayAudioButton(true);
  setplayAudio(false);
  if (target.label !== "") {
    loadAudio(target.label, setDisplayAudioButton);
  }
}

export {
  createModel,
  handleItemClick,
  renderedItem,
  mixer,
  handleSpriteClick,
  handelLableClick,
  labelsGroup,
  handleNavigationSwitch,
  updateNavigationItems,
  NavigationItems,
};
