import "./Navigation.css";
import React, { useState } from "react";
import { handleItemClick } from "../components/Model";
export default function Landscape({
  NavigationItems,
  handleNavigationClick,
  Sub,
}) {
  function handeNavItemClicked(t, index) {
    handleItemClick(t, index);
    handleNavigationClick(t);
  }
  return (
    <span className="navigation snav">
      {NavigationItems[0].targets.map((t, index) => (
        <React.Fragment>
          <span
            className={`navigationItem ${
              t.label === Sub.label ? "active" : null
            }`}
            onClick={() => handeNavItemClicked(t, index)}
          >
            {t.label}
          </span>
          <div className="navSub" style={{ marginLeft: Sub.left }}>
            {t.label === Sub.label
              ? Sub.childs.map((s) => (
                  <button
                    key={s.label}
                    className="navSubItem"
                    onClick={() => handleItemClick(s)}
                  >
                    {s.label}
                  </button>
                ))
              : null}
          </div>
          {index !== NavigationItems[0].targets - 1 ? <span>-</span> : null}
        </React.Fragment>
      ))}
    </span>
  );
}
