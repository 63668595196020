import React from "react";
import { ReactComponent as ReactLogo } from "../assets/Icons/eye-solid.svg";

const Pause = ({ color, width, height }) => {
  return (
    <div className="toggleLogo">
      <ReactLogo color={color} width={width} height={height} />
    </div>
  );
};
export default Pause;
