import { scene, camera, projectName } from "./setup";
import * as THREE from "three";
import { sounds } from "../helpers/getFiles";

var audioListener = new THREE.AudioListener();
camera.add(audioListener);

// instantiate audio object
var sound = new THREE.Audio(audioListener);

sound.onEnded();
scene.add(sound);

// instantiate a loader
var loader = new THREE.AudioLoader();

let gId;
// load a resource
function loadAudio(id, afterEnding) {
  const ProjectSounds = sounds[projectName].default;
  gId = id;
  loader.load(
    ProjectSounds[id],

    // onLoad callback
    function (audioBuffer) {
      // set the audio object buffer to the loaded object
      sound.setBuffer(audioBuffer);

      //   sound.setLoop(true)
      if (sound.isPlaying) {
        sound.stop();
        sound.play();
      } else {
        sound.play();
      }

      sound.source.onended = function () {
        // fff it works only once, we want it to work every time the same audio ends fff
        // let it work
        afterEnding(false)
        this.isPlaying = false;
      };
      // play the audio
    },

    // onProgress callback
    function (xhr) {
      // console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    },

    // onError callback
    function (err) {
      console.log("An error happened");
    }
  );
}
function stopSound(setDisplayAudioButton, setplayAudio) {
  if (sound.isPlaying) {  
    sound.stop();
    setDisplayAudioButton(false);
    setplayAudio(true);
  }
}
//  setTimeout(()=> stopSound(),5000)

export { loadAudio, sound, stopSound, gId };
