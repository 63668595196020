import React from "react";

const model = {
mincamerazoom:0.4,

  light: {
    amp: 0.6,
    front: 0.9,
    back: 0.9,
  },
  label: "Digestive system",
  id: "digestive",
  description: "",
  hidden: ["Outer_Body"],
  camtarget: { x: 0, y: 1.42, z:  -0.07},
  camera: { x: 0.2, y: 1.4, z: 1.6 },
  targets: [
    {
      shift: [
        { x: -0.06, y: 1.67, z: -0.03 },
        { x: -0.03, y: 1.625, z: -0.01 },
        { x: 0.0, y: 1.615, z: 0.01 },
      ],
      direction: { x: -0.15, y: 1.58, z: -0.07 },
      label: "Salivary Glands",
      labelYShift: 1,
      camtarget: { x: 0, y: 1.625, z: -0.01 },
      camera: { x: -0.6, y: 1.3, z: 0.3 },
      description: <h1>HELLO SALIVARY</h1>,
    },
    {
      shift: [{ x: 0.03, y: 1.64, z: 0.01 }],
      direction: { x: 0.1, y: 1.58, z: 0.03 },
      label: "Mouth",
      labelYShift: 1,
      camtarget: { x: 0, y: 1.66, z: 0.01 },
      camera: { x: 0.15, y: 1.6, z: 0.55 },
      animated: true,
      targets: [
        {
          shift: [{ x: 0.04, y: 1.65, z: 0.01 }],
          direction: { x: 0.1, y: 1.62, z: 0.0 },
          label: "Mouth",
          labelYShift: 1,
          camtarget: { x: 0, y: 1.66, z: 0.01 },
          camera: { x: 0.1, y: 1.72, z: 0.45 },
          animated: "hold",
          openlabel: true,
          small:true
        },
        {
          shift: [{ x:0.00, y:1.620, z: 0 }],
          direction: { x: -0.08, y:1.660, z:0.060 },
          label: "Tongue",
          labelYShift: -1,
          camtarget: { x: 0, y: 1.6, z: 0.01 },
          camera: { x: 0.1, y: 1.7, z: 0.5 },
          animated: "hold",
          small:true
        },
        {
          shift: [
            { x:-0.025, y:1.626, z:0.008 },
            { x:-0.028, y:1.630, z:0.000 },
            { x:-0.020, y:1.622, z:0.012 },
          ],
          direction:{ x: -0.052, y:1.588, z:0.1 },
          sublines: [
            // [
            //   [0.026,1.6417,-0.004],
            //   [0.026,1.6414,-0.004],
            // ],
            // [
            //   [0.025,1.640,0.000],
            //   [0.025,1.630,-0.00],
            // ],
            // [
            //   [0.024,1.635,0.010],
            //   [0.024,1.625,0.010],
            // ],
            // [
            //   [0.0222,1.6315,0.018],
            //   [0.0222,1.621,0.014],
            // ],
          ],
          label: "Molars",
          labelYShift: 1,
          camtarget: { x: 0, y: 1.66, z: 0.01 },
          camera: { x: 0.1, y: 1.7, z: 0.4 },
          animated: "hold",
          small:true
        },
        {
          shift: [
            { x: 0.019, y: 1.64, z: 0.035 },
            { x: 0.013, y:1.619, z:0.023 },
          ],
          direction: { x: 0.080, y:1.660, z:0.060 },
          label: "Canine",
          labelYShift: -1,
          camtarget: { x: 0, y: 1.66, z: 0.01 },
          camera: { x: -0.04, y: 1.62, z: 0.45 },
          animated: "hold",
          small:true
        },
        {
          shift: [
            { x: -0.003, y: 1.616, z:0.026 },
            { x: 0.008, y:1.617, z:0.025 },
          ],
          direction: { x: 0.05, y:1.588, z:0.1 },
          label: "incisors",
          labelYShift: 1,
          camtarget: { x: 0, y: 1.66, z: 0.01 },
          camera: { x: -0.04, y: 1.64, z: 0.45 },
          animated: "hold",
          small:true
        },
      ],
    },
    {
      shift: [{ x: 0.0, y: 1.6, z: -0.045 }],
      direction: { x: -0.12, y: 1.5, z: -0.085 },
      label: "Pharynx",
      labelYShift: 1,
      camtarget: { x: 0.0, y: 1.6, z: -0.045 },
      camera: { x: -0.5, y: 1.7, z: -0.2 },
    },
    {
      shift: [{ x: 0.0, y: 1.5, z: -0.1 }],
      direction: { x: 0.1, y: 1.45, z: -0.1 },
      label: "Esophagus",
      labelYShift: 1,
      camtarget: { x: 0.0, y: 1.5, z: -0.1 },
      camera: { x: -0.4, y: 1.3, z: 0.1 },
    },
    {
      shift: [{ x: -0.09, y: 1.25, z: -0.07 }],
      direction: { x: -0.2, y: 1.2, z: -0.03 },
      label: "Liver",
      labelYShift: 1,
      camtarget: { x: 0, y: 1.25, z: -0.07 },
      camera: { x: -0.4, y: 1.4, z: 0.5 },
    },
    {
      shift: [{ x: 0.07, y: 1.24, z: -0.06 }],
      direction: { x: 0.18, y: 1.18, z: -0.04 },
      label: "Stomach",
      labelYShift: 1,
      camtarget: { x: 0.07, y: 1.2, z: -0.06 },
      camera: { x: 0.4, y: 1.4, z: 0.4 },
    },
    {
      shift: [{ x: 0.03, y: 1.2, z: -0.08 }],
      direction: { x: 0.2, y: 1.11, z: -0.18 },
      label: "Pancreas",
      labelYShift: 1,
      camtarget: { x: 0.01, y: 1.2, z: -0.08 },
      camera: { x: -0.3, y: 1.1, z: -0.4 },
    },
    {
      shift: [{ x: 0.05, y: 1.13, z: -0.06 }],
      direction: { x: 0.18, y: 1.06, z: 0.01 },
      label: "Small Intestine",
      labelYShift: 1,
      camtarget: { x: 0.05, y: 1.13, z: -0.06 },
      camera: { x: -0.2, y: 1.1, z: 0.6 },
    },
    {
      shift: [{ x: -0.038, y: 1.21, z: -0.07 }],
      direction: { x: -0.1, y: 1.06, z: -0.24 },
      label: "Duodenum",
      labelYShift: 1,
      camtarget: { x: -0.038, y: 1.21, z: -0.07 },
      camera: { x: -0.5, y: 1, z: -0.7 },
    },
    {
      shift: [{ x: -0.02, y: 1.03, z: -0.06 }],
      direction: { x: 0.06, y: 0.95, z: -0.14 },
      label: "Ileum",
      labelYShift: 1,
      camtarget: { x: -0.02, y: 1.03, z: -0.06 },
      camera: { x: -0.3, y: 1, z: 0.5 },
    },
    {
      shift: [{ x: -0.09, y: 1.06, z: -0.07 }],
      direction: { x: -0.15, y: 0.99, z: -0.07 },
      label: "Large Intestine",
      labelYShift: 1,
      camtarget: { x: 0.0, y: 1.06, z: -0.07 },
      camera: { x: -0.4, y: 1, z: 0.7 },
    },
  ],
};

export default model;
