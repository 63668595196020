import React from "react";
import "./rightbarStyle.css";
import "./SidebarStyle.css";
import Icon from "../IconComponents/ToggleIcon";
export default function Descriptionbar({
  description,
  rightToggle,
  setrightToggle,
}) {
  return (
    <div className={`rightbarContainer ${rightToggle ? "openRB" : ""}`}>
      <div id="rightbar" className="rightbar">
        {description}
      </div>
      <button
        className={`Toggle RightToggle ${
          rightToggle ? "rotateRightToggle" : ""
        }`}
        id="RightToggle"
        onClick={() => setrightToggle(!rightToggle)}
      >
        <Icon color="#eee" width={20} height={20} />
      </button>
    </div>
  );
}
