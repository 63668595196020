import extract from "../../../helpers/extract";
function extractBones(root) {
  const Heart1 = extract(root, "Heart");
  const Heart = extract(Heart1, "Heart_(1)");

  const Circulatory_Heart003 = extract(Heart, "Circulatory_Heart003");
  const upperHeart = extract(Circulatory_Heart003, "Circulatory_Heart003_0");
  const lowerHeart = extract(Circulatory_Heart003, "Circulatory_Heart003_1");
  const Joints_Movement = extract(root, "Kine");
  const Joints_Movement_muscles = extract(Joints_Movement, "muscles");

  const Body = extract(root, "All");

  const Joints_Movement_bones1 = extract(Joints_Movement, "Bone001");

  const Femur1 = extract(Joints_Movement_bones1, "Femur");
  const Joints_Movement_bones = extract(Femur1, "Femur");

  const semanaContainer = extract(Joints_Movement_bones1, "Bone002");
  const Tibia = extract(semanaContainer, "Tibia");
  const semana = extract(Tibia, "muscles01");

  const Muscular_System = extract(Body, "Muscular_System");

  // upper Body START
  const upperLimbs = extract(Body, "Upper_limbs");
  const lowerLimbs = extract(Body, "Lower_Limbs");

  const rightArm = extract(upperLimbs, "Right_Arm_");
  const leftarm = extract(upperLimbs, "Left_Arm_");
  
  const pointParent = extract(leftarm,"Bone019")
  const point = extract(pointParent,"Point003_(1)")
  point.visible=false

  const ribcage = extract(upperLimbs, "Ribcage");
  const ribcageOuter = extract(upperLimbs, "Skeletal_Low");
  const backbone = extract(upperLimbs, "The_backbone");
  const skull = extract(upperLimbs, "The_Skull");

  // upper root END
  const eye = extract(Body, "Eye");
  const immovableJoints = extract(Body, "Immovable_joints");
  const leftMusles = extract(Body, "Left_Muscles");

  return {
    root,
    Body,
    Joints_Movement,
    Joints_Movement_muscles,
    Joints_Movement_bones,
    semana,
    Heart,
    Circulatory_Heart003,
    upperHeart,
    lowerHeart,
    Muscular_System,
    eye,
    upperLimbs,
    lowerLimbs,
    immovableJoints,
    leftMusles,
    rightArm,
    leftarm,
    ribcage,
    ribcageOuter,
    backbone,
    skull,
  };
}

export default extractBones;
