import React from "react";

const model = {
  label: "Structure",
  id: "plant",
  description: "",
  hidden: [],
  camtarget: { x: 0, y: 0.4, z: 0  },
  camera: { x: 0.2, y: 1.0, z: 1 },
  targets: [],
  childs: [],
};

export default model;
