import React from "react";
import CompressIcon from "../IconComponents/CompressIcon";
import ExpandIcon from "../IconComponents/ExpandIcon";
export default function Fullscrean({
  handleFullScreen,
  setIsPortrait,
  setFullScreen,
  FullScreen,
}) {
  function handleFullScreenClick() {
    if (FullScreen) {
      handleFullScreen.exit();
      setFullScreen(false);
      if (!window.screen.orientation.type === "landscape-primary") {
        setIsPortrait(true);
      }
    } else {
      handleFullScreen.enter();
      setFullScreen(true);
      window.screen.orientation.lock("landscape-primary");
      setIsPortrait(false);
    }
  }

  return (
    <React.Fragment>
      {FullScreen ? (
        <span className="icon" onClick={handleFullScreenClick}>
          <CompressIcon width={18} height={18} color="white" />
        </span>
      ) : (
        <span className="icon" onClick={handleFullScreenClick}>
          <ExpandIcon width={18} height={18} color="white" />
        </span>
      )}
    </React.Fragment>
  );
}
