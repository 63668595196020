import React from "react";

const model = {
  mincamerazoom:0.5,  
  light: {
    amp: 0.3,
    front: 0.75,
    back: 0.75,
  },
  label: "The Urinary system",
  id: "nervous",
  description: "",
  hidden: [],
  camtarget: { x: 0, y: 1.2, z: 0 },
  camera: { x: 0.2, y: 1.22, z: 1.4 },
  targets: [
    {
      shift: [{ x: -0.06, y: 1.18, z: -0.12 }],
      direction: { x: -0.21, y: 1.11, z: -0.03 },
      label: "kidney",
      labelYShift: 1,
      camtarget: { x: -0.06, y: 1.18, z: -0.12 },
      camera: { x: -0.4, y: 1.2, z: 0.6 },
    },
    {
      shift: [{ x: 0.034, y: 1.15, z: -0.097 }],
      direction: { x: 0.19, y: 1.11, z: -0.03 },
      label: "ureter",
      labelYShift: 1,
      camtarget: { x: 0.034, y: 1.15, z: -0.097 },
      camera: { x: 0.4, y: 1, z: 0.4 },
    },
    {
      shift: [{ x: 0.0, y: 1.0, z: -0.08 }],
      direction: { x: -0.1, y: 1.06, z: 0.04 },
      label: "Urinary bladder",
      labelYShift: -1,
      camtarget: { x: 0.0, y: 1, z: -0.08 },
      camera: { x: 0.4, y: 1, z: 0.4 },
      hidden: ["Skeletal_System_Low"],
      targets: [
        {
          shift: [{ x: 0.0, y: 1.0, z: -0.08 }],
          direction: { x: -0.1, y: 1.06, z: 0.04 },
          label: "Urinary bladder",
          labelYShift: -1,
          camtarget: { x: 0.0, y: 1.0, z: -0.08 },
          camera: { x: 0.4, y: 1.2, z: 0.6 },
          openlabel: true,
          small: true,
        },
        {
          shift: [{ x: 0.0, y:0.940, z: -0.099 }],
          direction: { x: -0.02, y: 0.88, z: -0.04 },
          label: "Urethra",
          labelYShift: 1,
          camtarget: { x: 0.0, y:0.940, z: -0.099 },
          camera: { x: 0.4, y: 0.9, z: 0.2 },
          small:true
        },
      ],
    },
  ],
};

export default model;
