const sounds = {
  cerebrum: require("../audio/cerebrum.ogg"),
  brain: require("../audio/brain.ogg"),
  centeral: require("../audio/centeral.ogg"),
  cerebellum: require("../audio/cerebellum.ogg"),
  init: require("../audio/init.ogg"),
  oblongata: require("../audio/oblongata.ogg"),
  peripheral: require("../audio/peripheral.ogg"),
  spinal: require("../audio/spinal.ogg"),
  nervous: require("../audio/structure.ogg"),
};

export default sounds;
