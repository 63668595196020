import * as THREE from "three";

export default (gltf) => {
  return {
    Mouth: THREE.AnimationUtils.subclip(gltf.animations[0], "R", 0, 21),
  };
};

// MusclesMovement: THREE.AnimationUtils.subclip(animations, "R", 30, 92),
// jointmovement: THREE.AnimationUtils.subclip(animations, "q", 0, 125),
// involuntary: THREE.AnimationUtils.subclip(animations, "R", 0, 29),
// freelymovable: THREE.AnimationUtils.subclip(animations, "R", 160, 230),
// Slightlymovable: THREE.AnimationUtils.subclip(animations, "R", 123, 160),
