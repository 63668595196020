import React from "react";
import Show from "../IconComponents/Eye";
import Hide from "../IconComponents/EyeSlash";

export default function Sound({ scene, Visible, setVisible }) {
  function handleVisible(state) {
    console.log("VISIVLE STATE", Visible)
    setVisible(state);
    const AI = scene.getChildByName("indexLables");
    AI.visible = state;
  }

  return (
    <React.Fragment>
      {Visible ? (
        <span className="icon " onClick={() => handleVisible(false)}>
          <Hide width={18} height={18} color="white" />
        </span>
      ) : (
        <span className="icon" onClick={() => handleVisible(true)}>
        <Show width={18} height={18} color="white" />
        </span>
      )}
    </React.Fragment>
  );
}
