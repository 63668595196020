import extract from "../../../helpers/extract";

function extractBones(root) {
  console.log(root)
  const outerBody = extract(root, "Body_NoGenitals_Low");
  const lungs = extract(root, "Respiratory_Lungs_Low_HalfOpacity");
  const stomach = extract(root, "stomach");
  const shoab=extract(root, "Two_Lungs");


  // const Skeletal_Mandible_Low = extract(skullBrain, "Skeletal_Mandible_Low");
  // const Skeletal_Cranium_Low = extract(skullBrain, "Skeletal_Cranium_Low");
  // const skull001 = extract(root, "Skull001");

  return {
    outerBody,
    root,
    lungs,
    stomach,
    shoab,
  };
}

export default extractBones;
