import React from "react";

const model = {
  mincamerazoom:1,
  hrBar: "The circularity system",
  light: {
    amp: 0.5,
    front: 0.8,
    back: 0.8,
  },
  label: "The circularity system",
  id: "circularity",
  description: "",
  hidden: [
    "Heart_Cutaway_parent",
    "Blood_Capillaries_parent",
    "Red_blood_cells",
    "Involuntary_Blood_Muscles",
    "Plasma",
    "White_blood_cell",
    "Blood_platelets",
  ],
  camtarget: { x: -1, y: 0.1, z: 0 },
  camera: { x: -1, y: 0, z: 3 },
  animated: true,
  targets: [
    {
      animated: true,
      light: {
        amp: 0.5,
        front: 0.8,
        back: 0.8,
      },
      label: "The Heart",
      id: "Heart",
      hidden: [
        "Heart_Complit_parent",
        "Blood_Capillaries_parent",
        "Red_blood_cells",
        "Involuntary_Blood_Muscles",
        "Plasma",
        "White_blood_cell",
        "Blood_platelets",
        "Plane001",
        "Plane002",
        "Plane003",
        "Plane004",
        "Plane005",
        "Plane016",
      ],
      shift: [{ x: 0, y: 9999.8, z: 0 }],
      direction: { x: -0.02, y: 9999, z: -0.04 },
      labelYShift: -1,
      camtarget: { x: -1, y: 0.09, z: -1 },
      camera: { x: -1, y: 0, z: 1.1 },
      targets: [
        {
          shift: [{ x: -1.339, y: 0.07, z: -0.85 }],
          direction: { x: -1.37, y: -0.06, z: -0.54 },
          label: "Right artium",
          labelYShift: 1,
          camtarget: { x: -1, y: 0.09, z: -1 },
          camera: { x: -1.4, y: 0, z: 1.1 },
          openlabel: true,
        },
        {
          label: "Left atrium",
          shift: [{ x: -0.79, y: 0.1, z: -1.05 }],
          direction: { x: -0.57, y: 0.02, z: -0.71 },
          labelYShift: 1,
          camtarget: { x: 0, y: 1.66, z: -0.05 },
          camera: { x: -0.4, y: 2.2, z: 0.6 },
          openlabel: true,
        },
        {
          shift: [{ x: -1.24, y: -0.2, z: -0.82 }],
          direction: { x: -1.35, y: -0.23, z: -0.57 },
          label: "Valve",
          labelYShift: 1,
          camtarget: { x: -1, y: 0.09, z: -1 },
          camera: { x: -1.1, y: 0, z: 0.6 },
          openlabel: true,
          // small: true,
        },
        {
          shift: [{ x: -1.11, y: -0.39, z: -0.85 }],
          direction: { x: -1.24, y: -0.41, z: -0.46 },
          label: "Right ventricle",
          labelYShift: 1,
          camtarget: { x: 0, y: 1.66, z: -0.05 },
          camera: { x: -0.4, y: 2.2, z: 0.6 },
          openlabel: true,
          // small: true,
        },
        {
          shift: [{ x: -0.67, y: -0.32, z: -0.89 }],
          direction: { x: -0.65, y: -0.35, z: -0.5 },
          label: "Left ventricle",
          labelYShift: 1,
          camtarget: { x: 0, y: 1.66, z: -0.05 },
          camera: { x: -0.4, y: 2.2, z: 0.6 },
          openlabel: true,
          // small: true,
        },
        {
          shift: [{ x: -0.95, y: -0.22, z: -0.81 }],
          direction: { x: -0.95, y: -0.5, z: -0.68 },
          label: "Wall",
          labelYShift: 1,
          camtarget: { x: 0, y: 1.66, z: -0.05 },
          camera: { x: -0.4, y: 2.2, z: 0.6 },
          openlabel: true,
          // small: true,
        },
        {
          shift: [{ x: -0.74, y: 0.02, z: -0.9 }],
          direction: { x: -0.65, y: -0.05, z: -0.5 },
          label: "valve",
          labelYShift: 1,
          camtarget: { x: 0, y: 1.66, z: -0.05 },
          camera: { x: -0.4, y: 2.2, z: 0.6 },
          openlabel: true,
          // small: true,
        },
      ],
      childs: [],
    },
    {
      light: {
        amp: 0.4,
        front: 0.8,
        back: 0.8,
      },
      label: "Blood Vessels",
      id: "Vessels",

      hidden: [
        "Heart_Complit_parent",
        "Blood_Capillaries_parent",
        "Red_blood_cells",
        "Involuntary_Blood_Muscles",
        "Plasma",
        "White_blood_cell",
        "Blood_platelets",
      ],
      shift: [{ x: 0, y: 9999.8, z: 0 }],
      direction: { x: -0.02, y: 9999.9, z: -0.04 },
      labelYShift: -1,
      camtarget: { x: -1, y: 0.15, z: -1 },
      camera: { x: -1, y: 0.1, z: 1.3 },
      childs: [
        {
          light: {
            amp: 0.4,
            front: 0.8,
            back: 0.8,
          },
          label: "Artries",
          id: "Artries",

          hidden: [
            "Heart_Complit_parent",
            "Blood_Capillaries_parent",
            "Red_blood_cells",
            "Involuntary_Blood_Muscles",
            "Plasma",
            "White_blood_cell",
            "Blood_platelets",
          ],
          shift: [{ x: 0, y: 1.8, z: 0 }],
          direction: { x: -0.02, y: 1.9, z: -0.04 },
          labelYShift: -1,
          camtarget: { x: -1, y: 0.15, z: -1 },
          camera: { x: -1, y: 0.5, z: 2 },
          childs: [],
          targets: [
            {
              shift: [{ x: -1.41, y: 0.407, z: -1.16 }],
              direction: { x: -1.5, y: 0.52, z: -1.155 },
              label: "Pulmonary artery",
              labelYShift: -1,
              camtarget: { x: -1, y: 0.09, z: -1 },
              camera: { x: -1.4, y: 0, z: 1.1 },
              openlabel: true,
              // small: true,
            },
            {
              shift: [{ x: -0.93, y: 0.54, z: -1.11 }],
              direction: { x: -0.74, y: 0.71, z: -1.19 },
              label: "Aorta",
              labelYShift: -1,
              camtarget: { x: -1, y: 0.09, z: -1 },
              camera: { x: -1.4, y: 0, z: 1.1 },
              openlabel: true,
              // small: true,
            },
            {
              shift: [{ x: -1.11, y: -0.39, z: -0.85 }],
              direction: { x: -1.24, y: -0.45, z: -0.68 },
              label: "Right ventricle",
              labelYShift: 1,
              camtarget: { x: 0, y: 1.66, z: -0.05 },
              camera: { x: -0.4, y: 2.2, z: 0.6 },
              openlabel: true,
              // small: true,
            },
            {
              shift: [{ x: -0.67, y: -0.32, z: -0.89 }],
              direction: { x: -0.65, y: -0.4, z: -0.6 },
              label: "Left ventricle",
              labelYShift: 1,
              camtarget: { x: 0, y: 1.66, z: -0.05 },
              camera: { x: -0.4, y: 2.2, z: 0.6 },
              openlabel: true,
              // small: true,
            },
          ],
        },
        {
          light: {
            amp: 0.4,
            front: 0.8,
            back: 0.8,
          },
          label: "Veins",
          id: "Veins",

          hidden: [
            "Heart_Complit_parent",
            "Blood_Capillaries_parent",
            "Red_blood_cells",
            "Involuntary_Blood_Muscles",
            "Plasma",
            "White_blood_cell",
            "Blood_platelets",
          ],
          shift: [{ x: 0, y: 1.8, z: 0 }],
          direction: { x: -0.02, y: 1.9, z: -0.04 },
          labelYShift: -1,
          camtarget: { x: -1, y: 0.15, z: -1 },
          camera: { x: -1, y: 0.5, z: 2 },
          childs: [],
          targets: [
            {
              shift: [{ x: -1.339, y: 0.07, z: -0.85 }],
              direction: { x: -1.37, y: -0.06, z: -0.54 },
              label: "Right artium",
              labelYShift: 1,
              camtarget: { x: -1, y: 0.09, z: -1 },
              camera: { x: -1.4, y: 0, z: 1.1 },
              openlabel: true,
            },
            {
              label: "Left atrium",
              shift: [{ x: -0.79, y: 0.1, z: -1.05 }],
              direction: { x: -0.57, y: 0.02, z: -0.71 },
              labelYShift: 1,
              camtarget: { x: 0, y: 1.66, z: -0.05 },
              camera: { x: -0.4, y: 2.2, z: 0.6 },
              openlabel: true,
            },
            {
              label: "Superior vena cava",
              shift: [{ x: -1.29, y: 0.58, z: -1.21 }],
              direction: { x: -1.53, y: 0.66, z: -1.38 },
              labelYShift: -1,
              camtarget: { x: 0, y: 1.66, z: -0.05 },
              camera: { x: -0.4, y: 2.2, z: 0.6 },
              openlabel: true,
            },
            {
              label: "Pulmonary veins",
              shift: [
                { x: -0.62, y: 0.265, z: -1.12 },
                { x: -0.59, y: 0.19, z: -1.08 },
              ],
              direction: { x: -0.4, y: 0.38, z: -1.1 },
              labelYShift: -1,
              camtarget: { x: 0, y: 1.66, z: -0.05 },
              camera: { x: -0.4, y: 2.2, z: 0.6 },
              openlabel: true,
            },
            {
              label: "Inferior vena cava",
              shift: [{ x: -1.19, y: -0.4, z: -1.15 }],
              direction: { x: -1.46, y: -0.52, z: -1.13 },
              labelYShift: 1,
              camtarget: { x: 0, y: 1.66, z: -0.05 },
              camera: { x: -0.4, y: 2.2, z: 0.6 },
              openlabel: true,
            },
          ],
        },
        {
          light: {
            amp: 0.8,
            front: 0.8,
            back: 0.8,
          },
          label: "Blood Caplilaries",
          id: "Caplilaries",
          animated: true,
          hidden: [
            "Heart_Cutaway_parent",
            "Heart_Complit_parent",
            "Red_blood_cells",
            "Involuntary_Blood_Muscles",
            "Plasma",
            "White_blood_cell",
            "Blood_platelets",
          ],
          shift: [{ x: 0, y: 1.8, z: 0 }],
          direction: { x: -0.02, y: 1.9, z: -0.04 },
          labelYShift: -1,
          camtarget: { x: 0.5, y: 0, z: 0 },
          camera: { x: 2, y: 0.9, z: 0.8 },
          targets: [
            {
              shift: [{ x: 0.26, y: 0.06, z: 0.15 }],
              direction: { x: 0.21, y: 0.2, z: 0.0 },
              label: "Body cells",
              labelYShift: -1,
              camtarget: { x: -1, y: 0.09, z: -1 },
              camera: { x: -1.4, y: 0, z: 1.1 },
              openlabel: true,
            },
            {
              shift: [{ x: 0.435, y: 0.02, z: -0.21 }],
              direction: { x: 0.405, y: 0.16, z: -0.32 },
              label: "Artery",
              labelYShift: -1,
              camtarget: { x: -1, y: 0.09, z: -1 },
              camera: { x: -1.4, y: 0, z: 1.1 },
              openlabel: true,
            },
            {
              shift: [{ x: 0.525, y: 0.0, z: -0.45 }],
              direction: { x: 0.535, y: 0.12, z: -0.58 },
              label: "Vein",
              labelYShift: -1,
              camtarget: { x: -1, y: 0.09, z: -1 },
              camera: { x: -1.4, y: 0, z: 1.1 },
              openlabel: true,
            },
            {
              shift: [{ x: 0.335, y: 0.13, z: 0.51 }],
              direction: { x: 0.315, y: 0.27, z: 0.4 },
              label: "Blood Capillaries",
              labelYShift: -1,
              camtarget: { x: -1, y: 0.09, z: -1 },
              camera: { x: -1.4, y: 0, z: 1.1 },
              openlabel: true,
            },
          ],
          childs: [],
        },
      ],
      targets: [],
    },
    {
      light: {
        amp: 0.8,
        front: 0.8,
        back: 0.8,
      },
      label: "Blood",
      id: "Blood",
      animated: true,
      hidden: [
        "Heart_Complit_parent",
        "Heart_Cutaway_parent",
        "Blood_Capillaries_parent",
      ],
      shift: [{ x: 0, y: 999.8, z: 0 }],
      direction: { x: -0.02, y: 999.9, z: -0.04 },
      labelYShift: -1,
      camtarget: { x: 0.2, y: 0, z: 0 },
      camera: { x: 0.6, y: 0.7, z: 1 },
      targets: [],
      childs: [
        {
          light: {
            amp: 0.6,
            front: 0.8,
            back: 0.8,
          },
          label: "Red blood cells",
          id: "Red",
          animated: true,
          hidden: [
            "Heart_Complit_parent",
            "Heart_Cutaway_parent",
            "Blood_Capillaries_parent",
            "White_blood_cell",
            "Blood_platelets",
            "Plasma",
          ],
          shift: [{ x: 0, y: 1.8, z: 0 }],
          direction: { x: -0.02, y: 1.9, z: -0.04 },
          labelYShift: -1,
          camtarget: { x: 0.2, y: 0, z: 0 },
          camera: { x: 0.6, y: 0.7, z: 1 },
          targets: [],
        },
        {
          light: {
            amp: 0.6,
            front: 0.8,
            back: 0.8,
          },
          label: "White blood cells",
          id: "white",
          animated: true,
          hidden: [
            "Heart_Complit_parent",
            "Heart_Cutaway_parent",
            "Blood_Capillaries_parent",
            "Red_blood_cells",
            "Blood_platelets",
            "Plasma",
          ],
          shift: [{ x: 0, y: 1.8, z: 0 }],
          direction: { x: -0.02, y: 1.9, z: -0.04 },
          labelYShift: -1,
          camtarget: { x: 0.2, y: 0, z: 0 },
          camera: { x: 0.6, y: 0.7, z: 1 },
          targets: [],
          childs: [],
        },
        {
          light: {
            amp: 0.6,
            front: 0.8,
            back: 0.8,
          },
          label: "Blood Platelets",
          id: "Platelets",
          animated: true,
          hidden: [
            "Heart_Complit_parent",
            "Heart_Cutaway_parent",
            "Blood_Capillaries_parent",
            "Red_blood_cells",
            "White_blood_cell",
            "Plasma",
          ],
          shift: [{ x: 0, y: 1.8, z: 0 }],
          direction: { x: -0.02, y: 1.9, z: -0.04 },
          labelYShift: -1,
          camtarget: { x: 0.2, y: 0, z: 0 },
          camera: { x: 0.6, y: 0.7, z: 1 },
          targets: [],
          childs: [],
        },
        {
          light: {
            amp: 0.6,
            front: 0.8,
            back: 0.8,
          },
          label: "Plasma",
          id: "Plasma",
          animated: true,
          hidden: [
            "Heart_Complit_parent",

            "Heart_Cutaway_parent",
            "Blood_Capillaries_parent",
            "Red_blood_cells",
            "White_blood_cell",
            "Blood_platelets",
          ],
          shift: [{ x: 0, y: 1.8, z: 0 }],
          direction: { x: -0.02, y: 1.9, z: -0.04 },
          labelYShift: -1,
          camtarget: { x: 0.2, y: 0, z: 0 },
          camera: { x: 0.6, y: 0.7, z: 1 },
          targets: [],
          childs: [],
        },
      ],
    },
  ],
};

export default model;
