import extract from "../../../helpers/extract";

function extractBones(root) {
  console.log(root);
  const Bplane = extract(root, "Plane001");
  const Skeletal_System_Low = extract(root, "Skeletal_System_Low");
  const UrinarySystem = extract(root, "UrinarySystem");

  // const Skeletal_Mandible_Low = extract(skullBrain, "Skeletal_Mandible_Low");
  // const Skeletal_Cranium_Low = extract(skullBrain, "Skeletal_Cranium_Low");
  // const skull001 = extract(root, "Skull001");

  return {
    root,
    Bplane,
    Skeletal_System_Low,
    UrinarySystem,
  };
}

export default extractBones;
