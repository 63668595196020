import extract from "../../../helpers/extract";

function extractBones(root) {
  console.log(root)
  const Digestive_System_Low = extract(root, "RootNode");
  const Outer_Body = extract(Digestive_System_Low, "Body_NoGenitals_Low");
  const Skeletal_Cranium_Low = extract(Digestive_System_Low, "Skeletal_Cranium_Low");
  
  // const Small_gut = extract(Digestive_System_Low, "Small_gut");
  // const pancreas = extract(Digestive_System_Low, "pancreas");
  // const Glands = extract(Digestive_System_Low, "Glands");
  // const Tongue = extract(Digestive_System_Low, "Tongue");
  // const bile = extract(Digestive_System_Low, "bile");
  // const Liver = extract(Digestive_System_Low, "Liver");
  // const Thick_gut = extract(Digestive_System_Low, "Thick_gut");
  const stomach = extract(Digestive_System_Low, "stomach");
  
  return {
    root,
    Outer_Body,
    stomach,
    Skeletal_Cranium_Low
    // Small_gut,
    // pancreas,
    // Glands,
    // Tongue,
    // bile,
    // Liver,
    // Thick_gut,
    // stomach,
  };
}

export default extractBones;
