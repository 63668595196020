import { FullScreen, useFullScreenHandle } from "react-full-screen";
import React from "react";
import "./App.css";
import Cat from "./components/Cat";
import { camera } from "../src/components/setup";

function App() {
  const handle = useFullScreenHandle();
  let counter = 0;
  function printCamPosition(e) {
    if (e.key === "f") {
      let { x, y, z } = camera.position;
      let newcoards = {
        x: x.toFixed(3),
        y: y.toFixed(3),
        z: z.toFixed(3),
      };
      console.log("CamN_" + counter, newcoards);
      counter++;
    }
  }
  return (
    <FullScreen handle={handle}>
      <div className="App" onKeyDown={printCamPosition}>
        <Cat handleFullScreen={handle} />
      </div>
    </FullScreen>
  );
}

export default App;
