import "./Navigation.css";
import React from "react";
import { handleNavigationSwitch } from "../components/Model";
import { animateCamTarget, animateCamera } from "../components/Animate";
import { updateNavigationItems } from "../components/Model";

export default function Landscape({
  NavigationItems,
  setNavigationItems,
  handleresetclick,
  Sub,
  hrBar,
}) {
  function handleNavigationClick(n, index) {
    const { label, camtarget, camera } = NavigationItems[index];
    let newNavItems = NavigationItems.slice(0, index + 1);
    updateNavigationItems(newNavItems);
    if (index !== NavigationItems.length - 1) {
      handleNavigationSwitch(NavigationItems[index]);
      setNavigationItems(newNavItems);
      animateCamTarget(camtarget);
      animateCamera(camera);
    }
  }

  return (
    <span className="navigation">
      {hrBar !== undefined ? (
        <React.Fragment>
          <span
            className={`navigationItem ${Sub.label == undefined ? "last" : ""}`}
            onClick={() => {
              handleresetclick(undefined, true);
            }}
          >
            {hrBar}
          </span>
        </React.Fragment>
      ) : (
        NavigationItems.map((n, index) => (
          <React.Fragment>
            <span
              className={`navigationItem ${
                index === NavigationItems.length - 1 ? "last" : ""
              }`}
              onClick={() => handleNavigationClick(n, index)}
            >
              {n.label}
            </span>
            {index !== NavigationItems.length - 1 ? <span>/</span> : null}
          </React.Fragment>
        ))
      )}
    </span>
  );
}
