import * as THREE from "three";
import { stats, render, controls, camera } from "./setup";
import TWEEN from "@tweenjs/tween.js";
import settings from "../variables/settings";
import { mixer, material } from "./Model";
var clock = new THREE.Clock();
let generalTime = 0;
let animateUVTarget = {};

function animateUV(bone) {
  animateUVTarget[bone.name] = bone.target;
}

function stopAnimateUV() {
  animateUVTarget = {};
}

function stopAnimation() {
  if (mixer) {
    mixer.stopAllAction();
  }
}

function handleModelAnimations(animated, id, animations) {
  if (animated === true) {
    // animations[id].forEach((anime) =>
    //   mixer.clipAction(anime).play()
    // );

    // function prom(anime){
    //   return new Promise((resolve,reject)=>{
    //     setTimeout(() => {
    //       mixer.clipAction(anime).play();
    //       console.log(index)
    //       resolve()
    //     }, 1000);
    //   })
    // }
    // animations.involuntary.forEach((anime, index) => {
    //   mixer.clipAction(anime).play();
    // });

    // mixer.clipAction(animations[id]).setLoop(THREE.LoopPingPong).play()
    // mixer.clipAction(animations[id]).setLoop(THREE.LoopOnce).fadeOut(1).play();
    if (animations[id].length > 0) {
      animations[id].forEach((anime) => mixer.clipAction(anime).play());
    } else {
      mixer
        .clipAction(animations[id])
        .setLoop(THREE.LoopOnce)
        .play().clampWhenFinished = true;
    }

    // mixer.clipAction(animations[0]).play();
    //  console.log(animations[id])
    //
    // setTimeout((e) => {
    //   mixer.paused = true;
    //   console.log("HEEEY")
    // }, 100);
  } else if (animated === "hold") {
    console.log("HOLD animation");
  } else {
    if (mixer !== undefined) {
      mixer.stopAllAction();
    }
  }
}

function updateMixer() {
  if (mixer) {
    var delta = clock.getDelta();
    mixer.update(delta);
  }
}
let requestID;
// this function is heavy , OPTIMIZE it as could as possible
const startAnimationLoop = (e) => {
  //   console.log("EEEE:",e)
  //   material.uniforms.time.value = e/10000;
  // console.log(clock.elapsedTime)
  stats.begin();
  generalTime = clock.elapsedTime;

  render();
  updateMixer();
  offsetUV();
  controls.update();
  stats.end();
  requestID = window.requestAnimationFrame(startAnimationLoop);
};

function offsetUV() {
  if (animateUVTarget.arrow !== undefined) {
    animateUVTarget.arrow.material.map.offset = new THREE.Vector2(
      0,
      -clock.elapsedTime / 1
    );
  }
  if (animateUVTarget.plasma !== undefined) {
    animateUVTarget.plasma.material.map.offset = new THREE.Vector2(
      -clock.elapsedTime / 12,
      0
    );
  }
}

function animateCamTarget(camtarget) {
  let controlsTarget = controls.target;
  let target = new THREE.Vector3(camtarget.x, camtarget.y, camtarget.z);

  const tween = new TWEEN.Tween(controlsTarget).to(
    target,
    settings.TargetTweenSpeed
  );
  tween.onUpdate(() => {
    const { x, y, z } = controlsTarget;
    controls.target = new THREE.Vector3(x, y, z);
  });
  tween.easing(TWEEN.Easing.Quartic.Out);
  tween.start();
}

function animateCamera(camtarget) {
  const START = new THREE.Spherical().setFromVector3(
    new THREE.Vector3(camtarget.x, camtarget.y, camtarget.z)
  );

  let END = new THREE.Spherical().setFromVector3(camera.position);

  console.log("********************************")
  console.log("INIT",START.theta)
  console.log("END",END.theta)
  console.log("DIFF",START.theta - END.theta)
  console.log("SUMM",START.theta + END.theta)
  console.log("********************************")

  if( START.theta - END.theta >Math.PI){
    END.theta+= (2*Math.PI)
  }

  const tween = new TWEEN.Tween(END).to(START, settings.cameraTweenSpeed);
  tween.onUpdate(() => {
    camera.position.setFromSphericalCoords(
      END.radius,
      END.phi,
      END.theta
    );

    // camera.position.setFromSphericalCoords(
    //   2.5,
    //   0.1,
    //   END.theta
    // );
  });

  tween.onComplete(() => {
    controls.enabled = true;
  });

  /*
Back: {In: ƒ, Out: ƒ, InOut: ƒ}
Bounce: {In: ƒ, Out: ƒ, InOut: ƒ}
Circular: {In: ƒ, Out: ƒ, InOut: ƒ}
Cubic: {In: ƒ, Out: ƒ, InOut: ƒ}
Elastic: {In: ƒ, Out: ƒ, InOut: ƒ}
Exponential: {In: ƒ, Out: ƒ, InOut: ƒ}
Linear: {None: ƒ}
Quadratic: {In: ƒ, Out: ƒ, InOut: ƒ}
Quartic: {In: ƒ, Out: ƒ, InOut: ƒ}
Quintic: {In: ƒ, Out: ƒ, InOut: ƒ}
Sinusoidal: {In: ƒ, Out: ƒ, InOut: ƒ}
  */
  tween.easing(TWEEN.Easing.Cubic.Out);
  tween.start();
}

export {
  startAnimationLoop,
  requestID,
  animateCamTarget,
  animateCamera,
  handleModelAnimations,
  animateUV,
  generalTime,
  stopAnimation,
};
