import extract from "../../../helpers/extract";

function extractBones(root) {
  
  // const Small_gut = extract(Digestive_System_Low, "Small_gut");
  // const pancreas = extract(Digestive_System_Low, "pancreas");
  // const Glands = extract(Digestive_System_Low, "Glands");
  // const Tongue = extract(Digestive_System_Low, "Tongue");
  // const bile = extract(Digestive_System_Low, "bile");
  // const Liver = extract(Digestive_System_Low, "Liver");
  // const Thick_gut = extract(Digestive_System_Low, "Thick_gut");
  
  return {
    root,
    // Small_gut,
    // pancreas,
    // Glands,
    // Tongue,
    // bile,
    // Liver,
    // Thick_gut,
    // stomach,
  };
}

export default extractBones;
