import * as THREE from "three";
import { handelLableClick, handleSpriteClick, labelsGroup } from "./Model";
import { camera } from "./setup";
var raycaster = new THREE.Raycaster();
var mouseVector = new THREE.Vector3();
var selectedObject = null;
var hoveredObject = null;

function getIntersects(event) {
  let x, y;
  if (event.type === "touchstart") {
    x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
    y = -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
  } else {
    x = (event.layerX / window.innerWidth) * 2 - 1;
    y = -(event.layerY / window.innerHeight) * 2 + 1;
  }

  mouseVector.set(x, y, 0.5);
  raycaster.setFromCamera(mouseVector, camera);

  return raycaster.intersectObject(labelsGroup, true);
}
function ononDocumentMouseMove(event) {
  event.preventDefault();
  if (hoveredObject) {
    // hoveredObject.material.opacity=0.9
    hoveredObject.material.color.set("#fff");
    hoveredObject = null;
  }

  var intersects = getIntersects(event);
  if (intersects.length > 0) {
    var res = intersects.filter(function (res) {
      return res && res.object;
    })[0];

    if (res && res.object) {
      hoveredObject = res.object;
      hoveredObject.material.color.set("#bbb");
    }
  }
}

function onDocumentMouseClick(event) {
  event.preventDefault();
  // event.x = (event.targetTouches[0].pageX / window.innerWidth) * 2 +-1;

  // event.y = (event.targetTouches[0].pageY / window.innerHeight) * 2 + 1;

  if (selectedObject) {
    // reset actions
    selectedObject = null;
  }
  var intersects = getIntersects(event);

  if (intersects.length > 0) {
    var res = intersects.filter(function (res) {
      return res && res.object;
    })[0];

    if (res && res.object) {
      selectedObject = res.object;
      // Make actions
      const { target } = selectedObject;
      if (selectedObject.kind === "label") {
        handelLableClick(target);
      } else {
        handleSpriteClick(target, selectedObject);
      }
    }
  }
}

export { onDocumentMouseClick, ononDocumentMouseMove };
