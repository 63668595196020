import React from "react";
import PlayIcon from "../IconComponents/PlayIcon";
import PauseIcon from "../IconComponents/PauseIcon";
import Redo from "../IconComponents/Redo";
import { sound, loadAudio, gId } from "../components/audio";
import { displayCoards } from "../components/helpers";
export default function Sound({
  DisplayAudioButton,
  setDisplayAudioButton,
  playAudio,
  setplayAudio,
}) {
  function handleReplayAudio() {
    setDisplayAudioButton(true);
    setplayAudio(false);
    // really crazy idea but worked!!!!!!!!!
    loadAudio(gId, setDisplayAudioButton);
  }
  function handleplayAudioClick(isPlay) {
    if (isPlay) {
      setplayAudio(false);
      sound.play();
    } else {
      setplayAudio(true);
      sound.pause();
    }
  }

  return (
    <React.Fragment>
      {DisplayAudioButton === 10 ? null : DisplayAudioButton === true ? (
        playAudio ? (
          <span
            className="icon "
            onClick={() => handleplayAudioClick(true)}
          >
            <PlayIcon width={18} height={18} color="white" />
          </span>
        ) : (
          <span
            className="icon"
            onClick={() => handleplayAudioClick(false)}
          >
            <PauseIcon width={18} height={18} color="white" />
          </span>
        )
      ) : (
        <span className="icon" onClick={() => handleReplayAudio()}>
          <Redo width={18} height={18} color="white" />
        </span>
      )}
    </React.Fragment>
  );
}
