import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("https://www.gstatic.com/draco/v1/decoders/");
dracoLoader.setDecoderConfig({ type: "js" });

const modelLoader = new GLTFLoader();
modelLoader.setDRACOLoader(dracoLoader);

const loadedModels = {}; //the switch trick ;)

function loadModel(loadingListener, filepath, projectName) {
  return new Promise((resolve, reject) => {
    if (loadedModels[projectName]) {
      resolve(loadedModels[projectName]);
    } else {
      modelLoader.load(
        filepath,
        function (gltf) {
          console.log(gltf);
          loadedModels[projectName] = gltf;
          resolve(gltf);
        },
        function (xhr) {
          loadingListener(xhr);
        },
        function (error) {
          console.log("ERROR on loading model", error);
          reject(error);
        }
      );
    }
  });
}

export { loadModel };
