import React, { useState, useEffect, useRef } from "react";
import { sceneSetup, scene } from "./setup";
import { addCustomSceneObjects } from "./SceneObjects";
import { startAnimationLoop } from "./Animate";
import Progress from "../UI/Progress";
import Descriptionbar from "../UI/Descriptionbar";
import FullScrean from "../UI/FullScreen";
import Landscable from "../UI/Landscable";
import Sound from "../UI/Sound";
import AIVisible from "../UI/AIVisible";
import HotController from "../UI/HotController";
import Navigation from "../UI/Navigation";
import { data } from "../helpers/getFiles";
import fixedprojectName from "../helpers/projectName";
import TestNavigation from "../UI/TestNavigation";
import { handleItemClick } from "../components/Model";

const projectData = data[fixedprojectName].default;
// import { data } from "../helpers/getFiles";
// let newdata =data["nerous"]
export default function Cat({ handleFullScreen }) {
  const textInput = useRef(null);
  const [projectName, setprojectName] = useState(fixedprojectName);
  const [NavigationItems, setNavigationItems] = useState([projectData]);
  const [completed, setcompleted] = useState(undefined);
  const [description, setdescription] = useState("");
  const [rightToggle, setrightToggle] = useState(true);
  const [sideToggle, setsideToggle] = useState(true);
  const [DisplayAudioButton, setDisplayAudioButton] = useState(10);
  const [playAudio, setplayAudio] = useState(false);
  const [ActiveID, setActiveID] = useState("");
  const [IsPortrait, setIsPortrait] = useState(false);
  const [FullScreen, setFullScreen] = useState(false);
  const [Visible, setVisible] = useState(true);
  const [Sub, setSub] = useState({ sub: [] });

  //10 is a special number to indicate it's hidden button

  function loadingListener(loaded) {
    let completed = ((loaded.loaded / loaded.total) * 100).toFixed(1);
    setcompleted(completed);
  }

  // *****************************************

  function handleNavigationClick(lol) {
    if (Sub.label === lol.label) {
      setSub({ sub: [] });
      handleItemClick(projectData);
    } else {
      setSub(lol);
    }
  }
  function handlereset() {
    setSub({ sub: [] });
    handleItemClick(projectData);
  }
  // *****************************************

  useEffect(() => {
    // const orientation = window.screen.orientation.type;
    // console.log("*************************");
    // console.log(BrowserView, MobileView);
    // console.log(isBrowser, isMobile);
    // console.log(mobileVendor, mobileModel);
    // console.log("*************************");

    // if (
    //   orientation === "portrait-secondary" ||
    //   orientation === "portrait-primary"
    // ) {
    //   setIsPortrait(true);
    // } else {
    //   setIsPortrait(false);
    // }
    const canvasTarget = textInput.current;
    sceneSetup(canvasTarget, projectName);
    addCustomSceneObjects(
      loadingListener,
      {
        setsideToggle,
        setrightToggle,
        setdescription,
        setActiveID,
        setDisplayAudioButton,
        setplayAudio,
        setNavigationItems,
        NavigationItems,
      },
      canvasTarget
    );

    startAnimationLoop();
  }, []);

  return (
    <React.Fragment>
      {!IsPortrait ? (
        <React.Fragment>
          {completed !== undefined && completed < 100 ? (
            <Progress completed={completed} bgcolor="#fc0" />
          ) : (
            <React.Fragment>
              <div className="appbar">
                <Navigation
                  NavigationItems={NavigationItems}
                  setNavigationItems={setNavigationItems}
                  handleresetclick={handlereset}
                  Sub={Sub}
                  hrBar={projectData.hrBar}
                />
                {projectData.hrBar ? (
                  <TestNavigation
                    NavigationItems={NavigationItems}
                    handleNavigationClick={handleNavigationClick}
                    Sub={Sub}
                    hrBar={projectData.hrBar}
                  />
                ) : null}

                <span className="right">
                  <Sound
                    DisplayAudioButton={DisplayAudioButton}
                    setDisplayAudioButton={setDisplayAudioButton}
                    playAudio={playAudio}
                    setplayAudio={setplayAudio}
                  />
                  <AIVisible
                    scene={scene}
                    Visible={Visible}
                    setVisible={setVisible}
                  />
                  <FullScrean
                    handleFullScreen={handleFullScreen}
                    FullScreen={FullScreen}
                    setFullScreen={setFullScreen}
                    setIsPortrait={setIsPortrait}
                  />
                </span>
              </div>
              {projectData.hrBar ? null : (
                <HotController
                  ActiveID={ActiveID}
                  setActiveID={setActiveID}
                  NavigationItems={NavigationItems}
                />
              )}
            </React.Fragment>
          )}
          <Descriptionbar
            description={description}
            rightToggle={rightToggle}
            setrightToggle={setrightToggle}
          />
        </React.Fragment>
      ) : (
        <Landscable
          handleFullScreen={handleFullScreen}
          setIsPortrait={setIsPortrait}
          setFullScreen={setFullScreen}
        />
      )}

      <div ref={textInput} className="canvas"></div>
    </React.Fragment>
  );
}

//clean up to prevent memory leak
//   componentWillUnmount() {
//     window.cancelAnimationFrame(requestID);
//     controls.dispose();
//   }
