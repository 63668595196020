import React, { useState } from "react";
import CompressIcon from "../IconComponents/CompressIcon";
import ExpandIcon from "../IconComponents/ExpandIcon";
export default function Landscape({ handleFullScreen, setIsPortrait ,setFullScreen}) {

  function handleFullScreenClick() {
    handleFullScreen.enter();
    setFullScreen(true);
    window.screen.orientation.lock("landscape-primary");
    setIsPortrait(false);
    setFullScreen(true)
  }

  return (
    <div className="enterFullScreen" onClick={handleFullScreenClick}>
      <div className="fullscreanmessage">
        Please click anywhere to enter <b> the fullScreenMode </b>
      </div>
    </div>
  );
}
