import React from "react";

export default function Progress({ completed, bgcolor }) {
  const containerStyles = {
    position: "fixed",
    left: "calc(50% - 40% - 50px )",
    height: 20,
    width: "80%",
    backgroundColor: "#e0e0de99",
    borderRadius: 5,
    top:20,
    margin: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
    transition: "width 0.2s ease-out",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
}
