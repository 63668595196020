import * as THREE from "three";
import { scene } from "./setup";
import { createModel } from "./Model";
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from "threejs-meshline";
import { onDocumentMouseClick, ononDocumentMouseMove } from "./Interactions";

const amplight = new THREE.AmbientLight("#ffffff",0.4);
let lightBack = new THREE.SpotLight(0xffffff ,0.7);
let lightFront = new THREE.SpotLight(0xffffff,0.7);

function changeLightInt(lightIntensity){
  const {amp , front, back} = lightIntensity
  amplight.intensity=amp
  lightBack.intensity=back
  lightFront.intensity=front
}

function addLights() {
  lightBack.position.set(2, 5, -7);
  lightFront.position.set(-2, 3, 7);

  scene.add(amplight);
  scene.add(lightBack);
  scene.add(lightFront);

  // scene.add( new THREE.SpotLightHelper(lightBack,"#ff00cc") );
  // scene.add( new THREE.SpotLightHelper( lightFront ,"#ccff00"));
}

const addCustomSceneObjects = (
  loadingListener,
  UIInteractions,
  canvasTarget
) => {

  addLights()
  createModel(loadingListener, UIInteractions);
  canvasTarget.addEventListener("click", onDocumentMouseClick, false);
  canvasTarget.addEventListener("mousemove", ononDocumentMouseMove, false);
  canvasTarget.addEventListener("touchstart", onDocumentMouseClick, false);
};

function putSphere(position) {
  const geometry = new THREE.SphereGeometry(0.009, 16, 16);
  const material = new THREE.MeshBasicMaterial({
    color: 0x000000,
    wireframe: false,
  });
  // position.addScalar(0.001)
  const { x, y, z } = position;
  geometry.translate(x, y, z);
  const shpere = new THREE.Mesh(geometry, material);
  scene.add(shpere);
  return shpere;
}

function putNestedLine(LinesGroup, material, sublines) {
  sublines.forEach((sub) => {
    const verts = [
      new THREE.Vector3().fromArray(sub[0]),
      new THREE.Vector3().fromArray(sub[1]),
    ];
    const line = new MeshLine();
    line.setVertices(verts);
    LinesGroup.add(new THREE.Mesh(line, material));
  });

  return LinesGroup;
}
function putLine(start, end, color = "#ffffff", sublines) {
  const material = new MeshLineMaterial({
    lineWidth: 0.001,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
    color: new THREE.Color(color),
    sizeAttenuation : true
  });
  const line = new MeshLine();
  const LinesGroup = new THREE.Group();

  line.setVertices([start, end]);
  let mesh = new THREE.Mesh(line, material);
  LinesGroup.add(mesh);
  if (sublines !== undefined) {
    putNestedLine(LinesGroup, material, sublines);
  }
  return LinesGroup;
}

function putBox(position) {
  const geometry = new THREE.BoxBufferGeometry(1.5, 1.5, 1);
  const material = new THREE.MeshBasicMaterial({ color: 0xbe2828 });
  const { x, y, z } = position;
  geometry.translate(x, y, z);
  const cube = new THREE.Mesh(geometry, material);
  scene.add(cube);
  return cube;
}

export { addCustomSceneObjects, putLine, putBox, putSphere ,changeLightInt};
